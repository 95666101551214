// Security
export const CLIENT_IP_UPDATE = 'client/ip-update';

// Sidenav
export const SIDENAV_ACTIVE_INDEX = 'sidenav/active-index';

// Theme
export const THEME_UPDATE = 'theme/update';

// Customizer const
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
