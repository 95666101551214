// ** React Imports
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Fragment, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { css } from "@emotion/css";

import { APP_PREFIX_PATH, ROLE_MODULE_PATH } from "configs/AppConfig";

// ** Third Party Components
import * as Icon from "react-feather";
import classnames from "classnames";

// ** Reactstrap Imports
import {
    NavItem,
    NavLink,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledTooltip,
    UncontrolledDropdown,
} from "reactstrap";

import Avatar from "components/Avatar";

// ** Action Imports
import navigation from "constants/navigation";
import { themeUpdate } from "redux/actions/general.actions";
import { userSignout } from "redux/actions/authentication.actions";
import { alertToast } from "redux/actions/alert.actions";
import { CheckRead } from "redux/actions/notification.action";

// * Component Imports

// * Model Imports
import { UserModel } from "models";

import $ from "jquery";

function Header(props) {
    const { checkread, notiList } = props;
    const user = new UserModel(props.user);

    const navigate = useNavigate();
    const [isSelectSideNav, setIsSelectSideNav] = useState([]);
    const [isOpenSideNav, setOpenSideNav] = useState(false);

    const location = useLocation();
    const [currentPath, setCurrentPath] = useState("/");

    const onToggleSidenav = (e) => {
        e.preventDefault();
        let body = $("div.page-main").children();
        if (body.hasClass("menu-open")) {
            body.removeClass("menu-open");
            body.addClass("menu-hide");
        } else {
            body.addClass("menu-open");
            body.removeClass("menu-hide");
        }

        setOpenSideNav(!isOpenSideNav);
    };

    const onSignout = async () => {
        props.signoutProcess();
    };

    const onToggleTheme = (e) => {
        e.preventDefault();
        props.themeUpdate(props.theme === 0 ? 1 : 0);
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const togglenoti = () => setDropdownOpen((prevState) => !prevState);

    // const selectSidenav = async (e) => {
    //     const target = $(e.target)
    //     const header = target.closest('.nav-item')
    //     const index = target.closest('.navigation.navigation-main')

    //     let listNavigation = [...isSelectSideNav]
    //     let newListNavigation = []
    //     let skip = [false]
    //     // console.log('target', target)
    //     // console.log('header', header)
    //     // console.log('index', index)

    //     console.log('listNavigation :', listNavigation)

    //     if ( listNavigation.length > 0 ) {
    //         for (let index = 0; index < listNavigation.length; index++) {
    //             skip[index] = (listNavigation[index].target[0].outerText === target[0].outerText)
    //             listNavigation[index].skip = skip[index]
    //         }

    //         if ( skip.includes(true) ) {
    //             for (let index = 0; index < listNavigation.length; index++) {
    //                 if ( skip[index] === true) break;
    //                 else {
    //                     listNavigation[index].skip = true
    //                 }
    //             }
    //         }

    //         console.log('listNavigation :', listNavigation, skip.includes(false))

    //         if ( skip.includes(false) ) {
    //             let isBeforeSkip = false
    //             for (let index = 0; index < listNavigation.length; index++) {
    //                 if (isBeforeSkip) {
    //                     break;
    //                 }

    //                 if (listNavigation[index].skip === false) {
    //                     isBeforeSkip = true
    //                     const tergetDelete = $(listNavigation[index].action[0])
    //                     console.log('tergetDelete :', listNavigation[index])
    //                     tergetDelete.removeClass('active');
    //                     tergetDelete.removeClass('open sidebar-group-active');
    //                     tergetDelete.removeClass('open menu-collapsed-open sidebar-group-active');
    //                 } else {
    //                     newListNavigation.push(listNavigation[index])
    //                 }
    //             }

    //             console.log('newListNavigation :', newListNavigation)
    //             setIsSelectSideNav(newListNavigation)

    //             // Single Nav
    //             if ( (header[0].classList).value.search('has-sub') === -1 ) {
    //                 target.closest('a').addClass('active');
    //                 setIsSelectSideNav([...newListNavigation, {
    //                     target: target,
    //                     action: target.closest('a')
    //                 }])
    //             }
    //             // Mutiple Nav
    //             else {
    //                 // nav-item has-sub open sidebar-group-active
    //                 // nav-item has-sub open menu-collapsed-open sidebar-group-active
    //                 for (let index = 0; index < newListNavigation.length; index++) {
    //                     if ( index === newListNavigation.length - 1 ) {
    //                         if ( newListNavigation.length > 1 ) {
    //                             newListNavigation[index].action.removeClass('open menu-collapsed-open sidebar-group-active')
    //                             newListNavigation[index].action.addClass('open sidebar-group-active')
    //                         }
    //                         else {
    //                             newListNavigation[index].action.removeClass('open sidebar-group-active')
    //                             newListNavigation[index].action.addClass('open menu-collapsed-open sidebar-group-active')
    //                         }
    //                     }
    //                 }

    //                 target.closest('a').addClass('active');
    //                 setIsSelectSideNav([...newListNavigation, {
    //                     target: target,
    //                     action: target
    //                 }])
    //             }
    //         }
    //         else return
    //     } else {
    //         if ( (header[0].classList).value.search('has-sub') === -1 ) {
    //             target.closest('a').addClass('active');
    //             setIsSelectSideNav([...isSelectSideNav, {
    //                 target: target,
    //                 action: target.closest('a')
    //             }])
    //         } else {
    //             header.addClass('open sidebar-group-active');
    //             setIsSelectSideNav([...isSelectSideNav, {
    //                 target: target,
    //                 action: header
    //             }])
    //         }
    //     }
    // };

    const buildSubMenu = (inputObj = []) => {
        return (
            <>
                {inputObj.map((obj) => (
                    <li key={obj.id}>
                        {obj.navLink !== undefined ? (
                            <Link
                                to={obj.navLink}
                                className="d-flex align-items-center">
                                <i data-feather="user"></i>
                                <span className="menu-title text-truncate">
                                    {obj.title}
                                </span>
                                <ul className="menu-content">
                                    {/* {(obj.children !== []? buildSubMenu(obj.children): null)} */}
                                </ul>
                            </Link>
                        ) : (
                            <></>
                        )}
                    </li>
                ))}
            </>
        );
    };

    const buildNavigation = () => {
        let mainNav = [];
        let subNav = [];
        let fullNav = [];

        mainNav = navigation.map((mainNavigation) => (
            <li className="navigation-header" key={mainNavigation.id}>
                <span>{mainNavigation.title}</span>
                <i data-feather="more-horizontal"></i>
            </li>
        ));

        subNav = navigation.map((eachNav) => {
            return eachNav.children.map((child) => (
                <li
                    className={`nav-item ${
                        child.children !== undefined ? "has-sub open" : ""
                    }`}
                    key={child.id}
                    // onClick={selectSidenav}
                >
                    {child.navLink !== undefined ? (
                        <Link
                            to={child.navLink}
                            className="d-flex align-items-center">
                            <i data-feather="user"></i>
                            <span className="menu-title text-truncate">
                                {child.title}
                            </span>
                        </Link>
                    ) : (
                        <></>
                    )}

                    <ul className="menu-content">
                        {child.children !== undefined
                            ? buildSubMenu(child.children)
                            : null}
                    </ul>
                </li>
            ));
        });

        for (let index = 0; index < mainNav.length; index++) {
            fullNav.push([mainNav[index], subNav[index]]);
        }

        return fullNav;
    };

    // display read
    const isRead = (data) => {
        if (data.status === "READ") {
            return true;
        } else return false;
    };

    // ** Function to toggle Theme (Light/Dark)
    const ThemeToggler = () => {
        if (props.theme === 0) {
            return <Icon.Sun className="ficon" onClick={onToggleTheme} />;
        } else {
            return <Icon.Moon className="ficon" onClick={onToggleTheme} />;
        }
    };

    /* eslint-disable */
    useEffect(() => {
        setCurrentPath(location.pathname);
        setOpenSideNav(false);
        let body = $("div.page-main").children();
        if (body.hasClass("menu-open")) {
            body.removeClass("menu-open");
            body.addClass("menu-hide");
        }
    }, [location]);

    useEffect(() => {
        if (props.theme === 0) $("body").removeClass("dark-layout");
        else $("body").addClass("dark-layout");
    }, [props.theme]);
    /* eslint-enable */

    return (
        <>
            <nav
                className={`header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl`}>
                <div className="navbar-container d-flex content">
                    {/* Left zone */}
                    <div className="bookmark-wrapper d-flex align-items-center">
                        {/* Hamberger */}
                        <ul className="navbar-nav d-xxl-none d-xl-none">
                            <NavItem className="mobile-menu me-auto">
                                <NavLink
                                    className="nav-menu-main menu-toggle hidden-xs is-active"
                                    onClick={() =>
                                        props.setMenuVisibility(true)
                                    }>
                                    <Icon.Menu className="ficon" />
                                </NavLink>
                            </NavItem>
                        </ul>
                        {/* Another Icon */}
                        {/* <ul className="nav navbar-nav bookmark-icons">
                            Some Icon
                        </ul> */}
                    </div>
                    {/* Right zone */}
                    <ul className="nav navbar-nav align-items-center ms-auto">
                        <NavItem className="d-lg-block">
                            <NavLink className="nav-link-style">
                                <ThemeToggler />
                            </NavLink>
                        </NavItem>

                        <UncontrolledDropdown tag="li" className="nav-item">
                            <DropdownToggle
                                href="/"
                                tag="a"
                                className="nav-link d-flex"
                                onClick={(e) => e.preventDefault()}>
                                <Icon.Bell />
                            </DropdownToggle>
                            <DropdownMenu end>
                                <div
                                    className={css`
                                        display: flex;
                                        justify-content: flex-start;
                                        margin: 0.5rem 1rem;
                                    `}>
                                    <h4 className="text-black w-100 kanit-font">
                                        การแจ้งเตือน
                                    </h4>
                                    <Link
                                        to={`../admin/noti/list`}
                                        className="text-body nowrap">
                                        ดูทั้งหมด
                                    </Link>
                                </div>

                                {notiList.map((data, index) => (
                                    <DropdownItem
                                        key={index}
                                        className={css`
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            justify-content: flex-start;
                                            gap: 1rem;
                                        `}
                                        onClick={() => {
                                            checkread({
                                                data: { id: data.id },
                                            });
                                        }}
                                        href={data.link}
                                        tag="a">
                                        <Avatar
                                            imgHeight="50"
                                            imgWidth="50"
                                            status="online"
                                        />
                                        <div
                                            className={css`
                                                display: flex;
                                                flex-direction: column;
                                            `}>
                                            <h5
                                                className={
                                                    isRead(data)
                                                        ? "text-muted"
                                                        : "text-black"
                                                }>
                                                {data.title}
                                            </h5>
                                            <p
                                                className={`${css`
                                                    margin-bottom: 0;
                                                `} ${
                                                    isRead(data)
                                                        ? "text-muted"
                                                        : ""
                                                }`}>
                                                {data.since}
                                            </p>
                                        </div>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown
                            tag="li"
                            className="dropdown-user nav-item">
                            <DropdownToggle
                                href="/"
                                tag="a"
                                className="nav-link dropdown-user-link d-flex"
                                onClick={(e) => e.preventDefault()}>
                                <div className="user-nav d-flex fd-column mr-1r d-sm-none d-xs-none">
                                    <span className="user-name fw-bold">
                                        {user.displayName()}
                                    </span>
                                    <span className="user-status">
                                        {"Admin"}
                                    </span>
                                </div>
                                <Avatar
                                    imgHeight="40"
                                    imgWidth="40"
                                    status="online"
                                />
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem
                                    onClick={() =>
                                        navigate(
                                            `${APP_PREFIX_PATH}/${ROLE_MODULE_PATH}/user/1`
                                        )
                                    }
                                    className="w-100">
                                    <Icon.User size={14} className="me-75" />
                                    <span className="align-middle">
                                        Profile
                                    </span>
                                </DropdownItem>
                                <DropdownItem
                                    onClick={onSignout}
                                    className="w-100">
                                    <Icon.Power size={14} className="me-75" />
                                    <span className="align-middle">Logout</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ul>
                </div>
            </nav>
        </>
    );
}

Header.defaultProps = {
    setMenuVisibility: () => {},
};

Header.propTypes = {
    themeUpdate: PropTypes.func.isRequired,
    setMenuVisibility: PropTypes.func,
    signoutProcess: PropTypes.func,
    alertProcess: PropTypes.func,
};

const mapStateToProps = (state) => ({
    sidenavActiveIndex: state.general.sidenavActiveIndex,
    theme: state.general.theme,
    width: state.general.width,
    user: state.user,
    notiList: state.noti.notiList || [],
});

export default connect(mapStateToProps, {
    themeUpdate: themeUpdate,
    signoutProcess: userSignout,
    alertProcess: alertToast,
    checkread: CheckRead,
})(Header);
