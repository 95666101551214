import { saveToLS, getFromLS } from "utility/LocalStorage";
import { FARM_MODULE, APD } from "constants/LocalhostConstant";
import { WAITING, SUCCESS } from "constants/Status/FarmModule/APD";

const mockAPDList = [
    {
        id: 1,
        topic: `หมายเลขขาย 1`,
        description: `ผู้ตรวจสอบ: ตาต้า`,
        status: WAITING,
        link: "1",
    },
    {
        id: 2,
        topic: `หมายเลขขาย 2`,
        description: `ผู้ตรวจสอบ: ตาต้า`,
        status: SUCCESS,
        link: "2",
    },
];

const mockBuyerList = [
    {
        id: 1,
        topic: `นายภาคิน เมธีชาญกิจ`,
        description: `กุ้งเบอร์ 0, 1, 2`,
        status: WAITING,
    },
    {
        id: 2,
        topic: `นายภาคิน เมธีชาญกิจ`,
        description: `กุ้งเบอร์ 0, 1, 2`,
        status: SUCCESS,
    },
];

const initialState = {
    apdList: mockAPDList,
    buyerList: mockBuyerList,
};

const farmModuleReducer = (
    state = getFromLS(FARM_MODULE, APD)
        ? getFromLS(FARM_MODULE, APD)
        : initialState,
    action
) => {
    const { type, payload } = action;
    let changed = state;

    switch (type) {
        default:
            break;
    }

    saveToLS(FARM_MODULE, APD, changed);
    return changed;
};

export default farmModuleReducer;
