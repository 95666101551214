import {
    CheckSquare,
    DollarSign,
    Settings,
    List,
    Grid,
} from "react-feather";

import { APP_PREFIX_PATH, FARM_MODULE_PATH } from "configs/AppConfig";
import { ACCESS_AFPD } from "constants/ability/farm-module/afpd";
import { ACCESS_APD } from "constants/ability/farm-module/apd";
import { CATCHING_ACCESS } from "constants/ability/farm-module/catching";
import { ACCESS_SAMPLING } from "constants/ability/farm-module/sampling";

const PREFIX = `${APP_PREFIX_PATH}/${FARM_MODULE_PATH}`;

/* eslint-disable */
export default [
    {
        header: "การจัดการฟาร์ม",
    },
    {
        id: "farmList",
        title: "รายการฟาร์ม",
        icon: <Grid />,
        navLink: `${PREFIX}/farms`,
    },
    {
        id: "afpd",
        title: "รายการซื้อลูกกุ้ง",
        icon: <DollarSign />,
        navLink: `${PREFIX}/afpd`,
        ability: {
            I: ACCESS_AFPD.I,
            a: ACCESS_AFPD.a,
        },
    },
    {
        id: "apd",
        title: "รายการขายกุ้ง",
        icon: <DollarSign />,
        navLink: `${PREFIX}/apd`,
        ability: {
            I: ACCESS_APD.I,
            a: ACCESS_APD.a,
        },
    },
    {
        id: "farmRandomShrimpSize",
        title: "รายการสุ่มไซต์",
        icon: <CheckSquare />,
        navLink: `${PREFIX}/sampling`,
        ability: {
            I: ACCESS_SAMPLING.I,
            a: ACCESS_SAMPLING.a,
        },
    },
    {
        id: "farmCatchShrimp",
        title: "รายการจับกุ้ง",
        icon: <List />,
        navLink: `${PREFIX}/catching`,
        ability: {
            I: CATCHING_ACCESS.I,
            a: CATCHING_ACCESS.a,
        },
    },
    {
        id: "farmSetting",
        title: "ตั้วค่าระบบฟาร์มใหญ่",
        icon: <Settings />,
        navLink: `${PREFIX}/setting`,
        // ability: {
        //     I: ACCESS_SETTING.I,
        //     a: ACCESS_SETTING.a,
        // },
    },
];
