import { AlertModel } from "models";
import {
    ALERT_CHANGE,
    ALERT_DISAPPEAR,
    ALERT_LOADING,
} from "../constants/alert.constant";

import toast from "react-hot-toast";

var timeout;

export const alertChange =
    (type, message, errors = []) =>
    (dispatch) => {
        dispatch({
            type: ALERT_CHANGE,
            payload: new AlertModel({
                status: true,
                type: type,
                message: message,
                errors: errors,
            }),
        });
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            dispatch({
                type: ALERT_DISAPPEAR,
                payload: false,
            });
        }, 3000);
    };

export const alertLoading = (loading) => (dispatch) => {
    dispatch({
        type: ALERT_LOADING,
        payload: loading,
    });
};

// https://react-hot-toast.com/
export const alertToast = (type, obj) => (dispatch) => {
    let positionObject = "top-right";
    if (obj.position !== undefined) {
        switch (obj.position) {
            case "top-center":
                positionObject = "top-center";
                break;
            case "top-left":
                positionObject = "top-left";
                break;
            case "bottom-right":
                positionObject = "bottom-right";
                break;
            case "bottom-center":
                positionObject = "bottom-center";
                break;
            case "bottom-left":
                positionObject = "bottom-left";
                break;
            default:
                positionObject = "top-left";
                break;
        }
    }

    switch (type) {
        case "success":
            return toast.success(obj.message, {
                position: positionObject,
            });
        case "error":
            return toast.error(obj.message, {
                position: positionObject,
            });
        default:
            return toast(obj.message, { position: positionObject });
    }
};
