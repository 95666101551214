import { CHECK_READ } from "redux/constants/notification";

export const CheckRead = (payload) => (dispatch) => {
    if(true) {
        dispatch({
            type: CHECK_READ,
            payload: {
                id: payload.data.id,
                status: "READ",
            },
        });
    }
    return true;
};
