import { AbilityBuilder, Ability } from "@casl/ability";
import {
    ACCESS_AFPD,
    AFPD_ASK_APPROVAL,
    AFPD_REQUISITION_ASK_APPROVAL,
    AFPD_REQUISITION_CREATE,
    AFPD_REQUISITION_VIEW,
    AFPD_UPDATE_GENERAL_INFO,
    AFPD_UPDATE_HASH_CHERRY,
    AFPD_UPDATE_PAYMENT,
    AFPD_UPDATE_SHRIMP_HEALTH,
    AFPD_VIEW_GENERAL_INFO,
    AFPD_VIEW_HASH_CHERRY,
    AFPD_VIEW_PAYMENT,
    AFPD_VIEW_SHRIMP_HEALTH,
} from "constants/ability/farm-module/afpd";
import {
    ACCESS_APD,
    APD_UPDATE_DOCUMENT,
    APD_UPDATE_PAYMENT,
    APD_VIEW_CATCHING_REPORT,
    APD_VIEW_DOCUMENT,
    APD_VIEW_PAYMENT,
} from "constants/ability/farm-module/apd";
import {
    CATCHING_ACCESS,
    CATCHING_REQUISITION_CREATE,
    CATCHING_REQUISITION_REJECT,
    CATCHING_REPORT_GENERAL_VIEW,
    CATCHING_REPORT_GENERAL_UPDATE,
    CATCHING_REPORT_SHRIMP_SIZE_VIEW,
    CATCHING_REPORT_SHRIMP_SIZE_UPDATE,
    CATCHING_REPORT_SHRIMP_WEIGHT_VIEW,
    CATCHING_REPORT_SHRIMP_WEIGHT_UPDATE,
    CATCHING_REPORT_SHRIMP_PRICE_VIEW,
    CATCHING_REPORT_SHRIMP_PRICE_UPDATE,
    CATCHING_REPORT_SHRIMP_SUMMARY_VIEW,
    CATCHING_REPORT_TRANSPORTATION_VIEW,
    CATCHING_REPORT_TRANSPORTATION_UPDATE,
    CATCHING_BUYER_VIEW,
    CATCHING_BUYER_UPDATE,
} from "constants/ability/farm-module/catching";
import {
    ACCESS_DAILY_REPORT,
    VIEW_BACTERIA,
    VIEW_BODYSHRIMP,
    VIEW_DETAIL,
    VIEW_FOOD,
    VIEW_LIVERSHRIMP,
    VIEW_OTHER,
    VIEW_SAMPLING,
    VIEW_WATER,
    VIEW_YOR,
    UPDATE_BASTERIA,
    UPDATE_BODYSHRIMP,
    UPDATE_DETAIL,
    UPDATE_FOOD,
    UPDATE_LIVERSHRIMP,
    UPDATE_OTHER,
    UPDATE_SAMPLING,
    UPDATE_WATER,
    UPDATE_YOR,
} from "constants/ability/farm-module/daily-report";
import {
    ACCESS_SAMPLING,
    SAMPLING_REQUISTION_REJECT,
    SAMPLING_REQUISTION_CREATE,
    SAMPLING_HEALTH_REPORT_VIEW_GENERAL_INFO,
    SAMPLING_HEALTH_REPORT_UPDATE_GENERAL_INFO,
    SAMPLING_HEALTH_REPORT_VIEW_SHRIMP_WEIGHT,
    SAMPLING_HEALTH_REPORT_UPDATE_SHRIMP_WEIGHT,
    SAMPLING_HEALTH_REPORT_VIEW_SHRIMP_HEALTH,
    SAMPLING_HEALTH_REPORT_UPDATE_SHRIMP_HEALTH,
    SAMPLING_HEALTH_REPORT_ASK_APPROVAL,
} from "constants/ability/farm-module/sampling";

import {
    ACCESS_LICENSE,
    CREATE_LICENSE,
    UPDATE_LICENSE,
    DELETE_LICENSE,
    SETTING_LICENSE,
    ACCESS_LICENSE_SETTING,
} from "constants/ability/license-module/license";

const { can, rules } = new AbilityBuilder(Ability);

const updateAbility = (ability, permissions) => {
    const permissionUpdated = permissions.filter((key) =>
        key.includes("apps.")
    );
    permissionUpdated.forEach((key) => {
        const [action, ability] = key.split(/_(.*)/);
        can(action, ability);
    });

    // // AFPD
    // for (const a of [
    //     ACCESS_AFPD,
    //     AFPD_ASK_APPROVAL,
    //     AFPD_REQUISITION_ASK_APPROVAL,
    //     AFPD_REQUISITION_CREATE,
    //     AFPD_REQUISITION_VIEW,
    //     AFPD_UPDATE_GENERAL_INFO,
    //     AFPD_UPDATE_HASH_CHERRY,
    //     AFPD_UPDATE_PAYMENT,
    //     AFPD_UPDATE_SHRIMP_HEALTH,
    //     AFPD_VIEW_GENERAL_INFO,
    //     AFPD_VIEW_HASH_CHERRY,
    //     AFPD_VIEW_PAYMENT,
    //     AFPD_VIEW_SHRIMP_HEALTH,
    // ]) {
    //     can(a.I, a.a);
    // }

    // // APD
    // for (const a of [
    //     ACCESS_APD,
    //     APD_UPDATE_DOCUMENT,
    //     APD_UPDATE_PAYMENT,
    //     APD_VIEW_CATCHING_REPORT,
    //     APD_VIEW_DOCUMENT,
    //     APD_VIEW_PAYMENT,
    // ]) {
    //     can(a.I, a.a);
    // }

    // // CATCHING
    // for (const a of [
    //     CATCHING_ACCESS,
    //     CATCHING_REQUISITION_CREATE,
    //     CATCHING_REQUISITION_REJECT,
    //     CATCHING_REPORT_GENERAL_VIEW,
    //     CATCHING_REPORT_GENERAL_UPDATE,
    //     CATCHING_REPORT_SHRIMP_SIZE_VIEW,
    //     CATCHING_REPORT_SHRIMP_SIZE_UPDATE,
    //     CATCHING_REPORT_SHRIMP_WEIGHT_VIEW,
    //     CATCHING_REPORT_SHRIMP_WEIGHT_UPDATE,
    //     CATCHING_REPORT_SHRIMP_PRICE_VIEW,
    //     CATCHING_REPORT_SHRIMP_PRICE_UPDATE,
    //     CATCHING_REPORT_SHRIMP_SUMMARY_VIEW,
    //     CATCHING_REPORT_TRANSPORTATION_VIEW,
    //     CATCHING_REPORT_TRANSPORTATION_UPDATE,
    //     CATCHING_BUYER_VIEW,
    //     CATCHING_BUYER_UPDATE,
    // ]) {
    //     can(a.I, a.a);
    // }

    // // DAILY REPORT
    // for (const a of [
    //     ACCESS_DAILY_REPORT,
    //     VIEW_BACTERIA,
    //     VIEW_BODYSHRIMP,
    //     VIEW_DETAIL,
    //     VIEW_FOOD,
    //     VIEW_LIVERSHRIMP,
    //     VIEW_OTHER,
    //     VIEW_SAMPLING,
    //     VIEW_WATER,
    //     VIEW_YOR,
    //     UPDATE_BASTERIA,
    //     UPDATE_BODYSHRIMP,
    //     UPDATE_DETAIL,
    //     UPDATE_FOOD,
    //     UPDATE_LIVERSHRIMP,
    //     UPDATE_OTHER,
    //     UPDATE_SAMPLING,
    //     UPDATE_WATER,
    //     UPDATE_YOR,
    // ]) {
    //     can(a.I, a.a);
    // }

    // // SAMPLING;
    // for (const a of [
    //     ACCESS_SAMPLING,
    //     SAMPLING_REQUISTION_REJECT,
    //     SAMPLING_REQUISTION_CREATE,
    //     SAMPLING_HEALTH_REPORT_VIEW_GENERAL_INFO,
    //     SAMPLING_HEALTH_REPORT_UPDATE_GENERAL_INFO,
    //     SAMPLING_HEALTH_REPORT_VIEW_SHRIMP_WEIGHT,
    //     SAMPLING_HEALTH_REPORT_UPDATE_SHRIMP_WEIGHT,
    //     SAMPLING_HEALTH_REPORT_VIEW_SHRIMP_HEALTH,
    //     SAMPLING_HEALTH_REPORT_UPDATE_SHRIMP_HEALTH,
    //     SAMPLING_HEALTH_REPORT_ASK_APPROVAL,
    // ]) {
    //     can(a.I, a.a);
    // }

    // // LICENSE
    // for (const a of [
    //     ACCESS_LICENSE,
    //     CREATE_LICENSE,
    //     UPDATE_LICENSE,
    //     DELETE_LICENSE,
    //     SETTING_LICENSE,
    //     ACCESS_LICENSE_SETTING,
    // ]) {
    //     can(a.I, a.a);
    // }

    ability.update(rules);
};

export const ability = new Ability(rules);

export default updateAbility;
