import { LabReportModel, ShrimpWeightModel} from "models";
import { ON_CHANGE_FORM_LAB_REPORT, ON_CHANGE_FORM_LAB_REPORT_ARRAY, FORM_REPORT_ADD_SHRIMP } from "redux/constants/LabReport.constant";

const initialState = new LabReportModel();

const FormLabReportReducer = (state = initialState, action) =>{
    switch (action.type){
        case ON_CHANGE_FORM_LAB_REPORT :
            return {...state, [action.payload.name]: action.payload.value};
        case FORM_REPORT_ADD_SHRIMP:
            return {...state, ShrimpWeight: state.ShrimpWeight.concat([new ShrimpWeightModel()]),
            };
        default:
            return state;
    }
};

export default FormLabReportReducer;