const SettingIcon = () => {
    return (
        <>
            <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.8047 12.2422C35.0859 12.875 34.875 13.5078 34.3828 14L31.3594 16.7422C31.4297 17.3047 31.5 17.9375 31.5 18.5C31.5 19.1328 31.4297 19.7656 31.3594 20.3281L34.3828 23.0703C34.875 23.5625 35.0859 24.1953 34.8047 24.8281C34.5234 25.6719 34.1719 26.4453 33.75 27.2188L33.3984 27.7812C32.9062 28.5547 32.4141 29.3281 31.8516 30.0312C31.4297 30.5234 30.7266 30.6641 30.0938 30.4531L26.2266 29.2578C25.2422 29.9609 24.1875 30.5234 23.1328 31.0156L22.2188 35.0234C22.0781 35.6562 21.5859 36.1484 20.9531 36.2891C19.9688 36.4297 18.9844 36.5 17.9297 36.5C16.9453 36.5 15.9609 36.4297 14.9766 36.2891C14.3438 36.1484 13.8516 35.6562 13.7109 35.0234L12.7969 31.0156C11.7422 30.5234 10.6875 29.9609 9.70312 29.2578L5.83594 30.4531C5.20312 30.6641 4.5 30.5234 4.07812 30.0312C3.51562 29.3281 3.02344 28.5547 2.53125 27.7812L2.17969 27.2188C1.75781 26.4453 1.40625 25.6719 1.125 24.8281C0.84375 24.1953 1.05469 23.5625 1.54688 23.0703L4.57031 20.3281C4.5 19.7656 4.5 19.1328 4.5 18.5C4.5 17.9375 4.5 17.3047 4.57031 16.7422L1.54688 14C1.05469 13.5078 0.84375 12.875 1.125 12.2422C1.40625 11.3984 1.75781 10.625 2.17969 9.85156L2.53125 9.28906C3.02344 8.51562 3.51562 7.74219 4.07812 7.03906C4.5 6.54688 5.20312 6.40625 5.83594 6.61719L9.70312 7.8125C10.6875 7.10938 11.7422 6.47656 12.7969 6.05469L13.7109 2.04688C13.8516 1.41406 14.3438 0.921875 14.9766 0.78125C15.9609 0.640625 16.9453 0.5 18 0.5C18.9844 0.5 19.9688 0.640625 20.9531 0.78125C21.5859 0.921875 22.0781 1.41406 22.2188 2.04688L23.1328 6.05469C24.1875 6.47656 25.2422 7.10938 26.2266 7.8125L30.0938 6.61719C30.7266 6.40625 31.4297 6.54688 31.8516 7.03906C32.4141 7.74219 32.9062 8.51562 33.3984 9.28906L33.75 9.85156C34.1719 10.625 34.5234 11.3984 34.8047 12.2422ZM18 24.125C21.0938 24.125 23.625 21.6641 23.625 18.5C23.625 15.4062 21.0938 12.875 18 12.875C14.8359 12.875 12.375 15.4062 12.375 18.5C12.375 21.6641 14.8359 24.125 18 24.125Z" fill="#7B808E"/>
            </svg>
        </>
    )
}

export default SettingIcon;