import {
    Box,
    Clock,
    Home,
    FilePlus,
    Package,
    PieChart,
    Settings,
} from "react-feather";
import { APP_PREFIX_PATH, STOCK_MODULE_PATH } from "configs/AppConfig";

const PREFIX = `${APP_PREFIX_PATH}/${STOCK_MODULE_PATH}`;

/* eslint-disable */
export default [
    {
        header: "คลังกลาง",
    },
    {
        id: "stock-subWarehouseList",
        title: "รายการคลังย่อย",
        icon: <Home />,
        navLink: `${PREFIX}/warehouse`,
    },
    {
        id: "stock-inventory",
        title: "สินค้าคงคลัง",
        icon: <Package />,
        navLink: `${PREFIX}/invertory`,
    },
    {
        id: "stock-productCategory",
        title: "ประเภทสินค้า",
        icon: <Box />,
        navLink: `${PREFIX}/category`,
    },
    {
        id: "stock-request",
        title: "รายการคำร้อง",
        icon: <FilePlus />,
        navLink: `${PREFIX}/report`,
    },
    {
        id: "stock-log",
        title: "ประวัติเบิกถอน",
        icon: <Clock />,
        navLink: `${PREFIX}/list`,
    },
    {
        id: "stock-warehouseSetting",
        title: "ต้ังค่าระบบคลัง",
        icon: <Settings />,
        navLink: `${PREFIX}/list`,
    },
];
