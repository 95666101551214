export const GET_WAREHOUSE_LIST = "GET_WAREHOUSE_LIST";
export const SET_WAREHOUSE_LIST = "SET_WAREHOUSE_LIST";
export const SET_WAREHOUSE_INFO = "SET_WAREHOUSE_INFO";

export const SET_REQUISITION = "SET_REQUISITION";

export const SET_INVENTORY = "SET_INVENTORY";

export const SET_HISTORY = "SET_HISTORY";

export const SET_CATEGORY = "SET_CATEGORY";
