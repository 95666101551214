import {
    SET_TASKS,
    SET_SELECTED_TASK,
    SET_FILTERS,
    UPDATE_SUCCESS,
    DELETE_REDUX_TASK,
    UPDATE_REDUX_TASK
} from "redux/constants/todoList.constants";
import { saveToLS, getFromLS } from "utility/LocalStorage";
import {
    TO_DO_LIST_MODULE,
    TO_DO_LIST_MANAGEMENT,
} from "constants/LocalhostConstant";

const initialState = {
    tasks: Array.from({ length: 10 }, (v, i) => {
        return {
            id: i,
            topic: "Some Task",
            category: "SoidPreparation",
            startDate: "2023-03-19T17:00:00.000Z",
            endDate: "2023-03-30T17:00:00.000Z",
            assignee: "63d82cc8415eb445caf4a1c6",
        };
    }),
    filters: {
        filter: "",
        q: "",
        sort: "",
        tag: "",
    },
    tags: [
        {
            id: "SoidPreparation",
            text: "เตรียมดิน",
            color: "#AE7F7F",
        },
        {
            id: "WaterPreparation",
            text: "เติมน้ำ",
            color: "#0092FF",
        },
        {
            id: "StockPreparation",
            text: "เตรียมอุปกรณ์",
            color: "#7367F0",
        },
        {
            id: "UsesPreparation",
            text: "ใช้งานอุปกรณ์",
            color: "#EA5455",
        },
        {
            id: "EtcPreparation",
            text: "อื่นๆ",
            color: "#28C76F",
        },
    ],
    selectedTask: {},
};

const todoListReducer = (
    state = getFromLS(TO_DO_LIST_MODULE, TO_DO_LIST_MANAGEMENT)
        ? getFromLS(TO_DO_LIST_MODULE, TO_DO_LIST_MANAGEMENT)
        : initialState,
    action
) => {
    const { type, payload } = action;
    let changed = state;

    switch (type) {
        case SET_TASKS: {
            changed = {
                ...state,
                // tasks: payload.docs,
                ...payload,
            };
            break;
        }

        case SET_SELECTED_TASK: {
            changed = {
                ...state,
                selectedTask: payload,
            };
            break;
        }

        case SET_FILTERS: {
            changed = {
                ...state,
                filters: payload,
            };
            break;
        }

        case UPDATE_SUCCESS: {
            let listTask = state.docs;
            for (var index in listTask) {
                if (listTask[index]._id === payload) {
                    listTask[index].isDone = true;
                }
            }
            changed = {
                ...state,
                docs: listTask,
            };
            break;
        }

        case UPDATE_REDUX_TASK: {
            console.log(payload)
            let listTask = state.docs;
            for (var index in listTask) {
                if (listTask[index]._id === payload._id) {
                    listTask[index] = payload
                }
            }
            changed = {
                ...state,
                docs: listTask,
            };
            break;
        }

        case DELETE_REDUX_TASK: {
            let listTask = state.docs.filter(obj => obj._id !== payload);
            changed = {
                ...state,
                docs: listTask,
            };
            break;
        }

        default:
            break;
    }

    saveToLS(TO_DO_LIST_MODULE, TO_DO_LIST_MANAGEMENT, changed);
    return changed;
};

export default todoListReducer;
