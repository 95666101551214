// ** React Imports
import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";

// ** View Imports
import AdminViews from "views/admin-views";

// ** Custom
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import AlertPopup from "components/AlertPopup";

// ** Third Party Components
import classnames from "classnames";
import { Toaster } from "react-hot-toast";

// ** Reactstrap Imports
import { Navbar } from "reactstrap";
import { isDesktop } from "utility/Responsive";
import { AbilityContext } from "utility/ability/createContextualCan";
import updateAbility from "utility/ability/updateAbility";

export const AdminLayout = (props) => {
    const { canAbility } = props;
    const isHidden = props.layoutStore.menuHidden;
    const contentWidth = props.layoutStore.contentWidth;
    const menuCollapsed = props.layoutStore.menuCollapsed;

    const ability = useContext(AbilityContext);

    useEffect(() => {
        if (canAbility) {
            updateAbility(ability, canAbility);
        }
    }, [canAbility]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [menuVisibility, setMenuVisibility] = useState(false);

    // ** Update Window Width
    const handleWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    };

    //** Sets Window Size & Layout Props
    useEffect(() => {
        if (window !== undefined) {
            window.addEventListener("resize", handleWindowWidth);
        }
    }, [windowWidth]);

    return (
        <>
            <AlertPopup />
            <div
                className={classnames(
                    "wrapper vertical-layout navbar-floating footer-static",
                    {
                        // Modern Menu
                        "vertical-menu-modern": windowWidth >= 1200,
                        "menu-collapsed": menuCollapsed && windowWidth >= 1200,
                        "menu-expanded": !menuCollapsed && windowWidth >= 1200,

                        // Overlay Menu
                        "vertical-overlay-menu": windowWidth < 1200,
                        "menu-hide": !menuVisibility && windowWidth < 1200,
                        "menu-open": menuVisibility && windowWidth < 1200,
                    }
                )}>
                <Header setMenuVisibility={setMenuVisibility} />
                <Sidebar menuCollapsed={menuCollapsed} />

                {/* Vertical Nav Menu Overlay */}
                <div
                    className={classnames("sidenav-overlay", {
                        show: menuVisibility,
                    })}
                    onClick={() => setMenuVisibility(false)}></div>
                {/* Vertical Nav Menu Overlay */}

                <div className="app-content content">
                    <div className="content-overlay"></div>
                    <div className="header-navbar-shadow"></div>
                    <div className="content-wrapper container-xxl p-0">
                        <div className="content-header row"></div>
                        <div className="content-body">
                            <AdminViews />
                        </div>
                    </div>
                </div>
            </div>
            <Toaster />
        </>
    );
};

const mapStateToProps = (state) => ({
    canAbility: state?.user?.role?.feConfig,
    layoutStore: state.layout,
});

export default connect(mapStateToProps)(React.memo(AdminLayout));
