import UsersRoute from "./Users";
import RoleRoute from "./Roles";

const RouteRoleModule = [
    {
        path: "user",
        children: UsersRoute,
    },
    {
        path: "role",
        children: RoleRoute,
    },
];

export default RouteRoleModule;
