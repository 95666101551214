import { saveToLS, getFromLS } from "utility/LocalStorage";
import { STOCK_MODULE, WHAREHOUSE } from "constants/LocalhostConstant";
import {
    SET_WAREHOUSE_LIST,
    SET_WAREHOUSE_INFO,
    SET_REQUISITION,
    SET_INVENTORY,
    SET_HISTORY,
    SET_CATEGORY,
} from "../constants/stock.constants";

const DATA_WAREHOUSE_SELECTED = {
    id: 1,
    topic: "คลังหลัก",
    description: "Main Warehouse Level",
    status: "active",
};

const DATA_REQUISITION_LIST = [
    {
        tier: "1",
        type: 2,
        name: "ต้า",
        status: "success",
        cost: 100,
    },
    {
        tier: "2",
        type: 2,
        name: "ต้า",
        status: "panding",
        cost: 100,
    },
    {
        tier: "3",
        type: 2,
        name: "ต้า",
        status: "prepare",
        cost: 100,
    },
    {
        tier: "4",
        type: 2,
        name: "ต้า",
        status: "tranfer",
        cost: 100,
    },
    {
        tier: "5",
        type: 2,
        name: "ต้า",
        status: "failed",
        cost: 100,
    },
];

const DATA_INVENTORY = {
    type: "ลูกปืน",
    items: [
        {
            list: "ลูกปืน",
            atLeast: "3",
            unit: "กระสอบ",
            company: "SCB",
            represent: "คุณต้า",
            phoneNumber: "09123123123",
        },
        {
            list: "ลูกปืน",
            atLeast: "3",
            unit: "กระสอบ",
            company: "SCB",
            represent: "คุณต้า",
            phoneNumber: "09123123123",
        },
        {
            list: "ลูกปืน",
            atLeast: "3",
            unit: "กระสอบ",
            company: "SCB",
            represent: "คุณต้า",
            phoneNumber: "09123123123",
        },
        {
            list: "ลูกปืน",
            atLeast: "3",
            unit: "กระสอบ",
            company: "SCB",
            represent: "คุณต้า",
            phoneNumber: "09123123123",
        },
    ],
};

const DATA_HISTORY = [
    {
        tier: "1",
        type: 2,
        name: "ต้า",
        status: "success",
        cost: 100,
    },
    {
        tier: "2",
        type: 2,
        name: "ต้า",
        status: "panding",
        cost: 100,
    },
    {
        tier: "3",
        type: 2,
        name: "ต้า",
        status: "prepare",
        cost: 100,
    },
    {
        tier: "4",
        type: 2,
        name: "ต้า",
        status: "tranfer",
        cost: 100,
    },
    {
        tier: "5",
        type: 2,
        name: "ต้า",
        status: "failed",
        cost: 100,
    },
];

const DATA_CATEGORY = [
    {
        tier: "1",
        type: "เคมีภัณท์",
    },
    {
        tier: "2",
        type: "ลูกปืืน",
    },
    {
        tier: "3",
        type: "ไฟฟ้า",
    },
    {
        tier: "3",
        type: "อุปกรณ์",
    },
];

const initialState = {
    warehouse: {
        list: [],
        info: DATA_WAREHOUSE_SELECTED,
    },
    requisition: {
        list: DATA_REQUISITION_LIST,
    },
    inventory: {
        list: DATA_INVENTORY,
    },
    history: {
        list: DATA_HISTORY,
    },
    category: {
        list: DATA_CATEGORY,
    },
};

const stockModuleReducer = (
    state = getFromLS(STOCK_MODULE, WHAREHOUSE)
        ? getFromLS(STOCK_MODULE, WHAREHOUSE)
        : initialState,
    action
) => {
    const { type, payload } = action;
    let changed = state;

    switch (type) {
        // WAREHOUSE
        case SET_WAREHOUSE_LIST: {
            changed.warehouse.list = payload;
            break;
        }

        case SET_WAREHOUSE_INFO: {
            changed.warehouse.selected.info = payload;
            break;
        }

        case SET_REQUISITION: {
            changed.requisition.list = payload;
        }

        // INVENTORY
        case SET_INVENTORY: {
            changed.inventory.list = payload;
            break;
        }

        // HISTORY
        case SET_HISTORY: {
            changed.history.list = payload;
        }

        // CATEGORY
        case SET_CATEGORY: {
            changed.category.list = payload;
        }

        default:
            break;
    }

    saveToLS(STOCK_MODULE, WHAREHOUSE, changed);
    return changed;
};

export default stockModuleReducer;
