export const ACCESS_APD = {
    I: "access",
    a: "apps.farm_module.apd.access",
};
export const APD_UPDATE_DOCUMENT = {
    I: "put",
    a: "apps.farm_module.apd.document.update_document",
};
export const APD_VIEW_DOCUMENT = {
    I: "get",
    a: "apps.farm_module.apd.document.view_document",
};
export const APD_UPDATE_PAYMENT = {
    I: "put",
    a: "apps.farm_module.apd.payment.update_payment",
};
export const APD_VIEW_CATCHING_REPORT = {
    I: "get",
    a: "apps.farm_module.apd.catching_form.view_catching_report",
};
export const APD_VIEW_PAYMENT = {
    I: "get",
    a: "apps.farm_module.apd.payment.view_payment",
};
