import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { onMounted } from 'helpers/frontend';

import 'css/page-misc.css';


function Page404(props) {
  
  /* eslint-disable */
	useEffect(() => {
    onMounted('vertical-layout vertical-menu-modern blank-page navbar-floating footer-static');
  }, []);
  /* eslint-enable */

  return (
    <div className="app-content content">
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <div className="misc-wrapper">
              <Link className="brand-logo" to="/">
                <img className="brand-img" src="/assets/img/logo.png" alt="App Logo" />
                <h2 className="brand-text text-primary ms-1">Tech Origin</h2>
              </Link>
              <div className="misc-inner p-2 p-sm-3">
                <div className="w-100 text-center">
                  <h2 className="mb-1">ไม่พบหน้าที่ค้นหา</h2>
                  <p className="mb-2">Oops! 😖 The requested URL was not found on this server.</p>
                  <div>
                    <Link className="btn btn-primary mb-2 btn-sm-block" to="/">
                      กลับสู่หน้าแรก
                    </Link>
                  </div>
                  <img className="img-fluid" src="./assets/img/pages/error.svg" alt="Error page" />
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Page404;