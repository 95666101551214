import { lazy } from "react";

const InventoryPage = lazy(async () => {
    return import("./Inventory");
});
const NewProductPage = lazy(async () => {
    return import("./NewProduct");
});
const AddStockPage = lazy(async () => {
    return import("./AddStock");
});

const RouteInventory = [
    {
        index: true,
        path: "",
        element: <InventoryPage />,
    },
    {
        index: true,
        path: "new",
        element: <NewProductPage />,
    },
    {
        index: true,
        path: "add",
        element: <AddStockPage />,
    },
];

export default RouteInventory;
