const date = new Date();
export const prevDay = new Date().getDate() - 1;
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

export const mockCalendar1 = {
    events: [
        {
            id: 1,
            url: "",
            title: "Design Review",
            start: date,
            end: nextDay,
            allDay: false,
            extendedProps: {
                calendar: "High",
            },
        },
        {
            id: 2,
            url: "",
            title: "Meeting With Client",
            start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
            allDay: true,
            extendedProps: {
                calendar: "High",
            },
        },
        {
            id: 3,
            url: "",
            title: "Family Trip",
            allDay: true,
            start: new Date(date.getFullYear(), date.getMonth() + 1, -9),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -7),
            extendedProps: {
                calendar: "High",
            },
        },
        {
            id: 4,
            url: "",
            title: "Doctor's Appointment",
            start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
            allDay: true,
            extendedProps: {
                calendar: "Medium",
            },
        },
        {
            id: 5,
            url: "",
            title: "Dart Game?",
            start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
            allDay: true,
            extendedProps: {
                calendar: "Medium",
            },
        },
        {
            id: 6,
            url: "",
            title: "Meditation",
            start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
            allDay: true,
            extendedProps: {
                calendar: "Medium",
            },
        },
        {
            id: 7,
            url: "",
            title: "Dinner",
            start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
            allDay: true,
            extendedProps: {
                calendar: "Low",
            },
        },
        {
            id: 8,
            url: "",
            title: "Product Review",
            start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
            allDay: true,
            extendedProps: {
                calendar: "Low",
            },
        },
        {
            id: 9,
            url: "",
            title: "Monthly Meeting",
            start: nextMonth,
            end: nextMonth,
            allDay: true,
            extendedProps: {
                calendar: "Low",
            },
        },
        {
            id: 10,
            url: "",
            title: "Monthly Checkup",
            start: prevMonth,
            end: prevMonth,
            allDay: true,
            extendedProps: {
                calendar: "Low",
            },
        },
    ],
};

export const eventInitial = [
    {
        id: 1,
        url: "",
        title: "Design Review",
        start: "2022-12-07T13:47:48.093Z",
        end: "2022-12-08T13:47:48.093Z",
        allDay: false,
        extendedProps: {
            calendar: "High",
        },
    },
    {
        id: 2,
        url: "",
        title: "Meeting With Client",
        start: "2022-12-19T17:00:00.000Z",
        end: "2022-12-20T17:00:00.000Z",
        allDay: true,
        extendedProps: {
            calendar: "High",
        },
    },
    {
        id: 3,
        url: "",
        title: "Family Trip",
        allDay: true,
        start: "2022-12-21T17:00:00.000Z",
        end: "2022-12-23T17:00:00.000Z",
        extendedProps: {
            calendar: "High",
        },
    },
    {
        id: 4,
        url: "",
        title: "Doctor's Appointment",
        start: "2022-11-28T17:00:00.000Z",
        end: "2022-12-19T17:00:00.000Z",
        allDay: true,
        extendedProps: {
            location: "",
            description: "",
            guests: {},
            calendar: "High",
        },
    },
    {
        id: 5,
        url: "",
        title: "Dart Game?",
        start: "2022-12-17T17:00:00.000Z",
        end: "2022-12-18T17:00:00.000Z",
        allDay: true,
        extendedProps: {
            calendar: "Medium",
        },
    },
    {
        id: 6,
        url: "",
        title: "Meditation",
        start: "2022-12-17T17:00:00.000Z",
        end: "2022-12-18T17:00:00.000Z",
        allDay: true,
        extendedProps: {
            calendar: "Medium",
        },
    },
    {
        id: 7,
        url: "",
        title: "Dinner",
        start: "2022-12-17T17:00:00.000Z",
        end: "2022-12-18T17:00:00.000Z",
        allDay: true,
        extendedProps: {
            calendar: "Medium",
        },
    },
    {
        id: 8,
        url: "",
        title: "Product Review",
        start: "2022-12-17T17:00:00.000Z",
        end: "2022-12-18T17:00:00.000Z",
        allDay: true,
        extendedProps: {
            calendar: "Low",
        },
    },
    {
        id: 9,
        url: "",
        title: "Monthly Meeting",
        start: "2022-12-31T17:00:00.000Z",
        end: "2022-12-31T17:00:00.000Z",
        allDay: true,
        extendedProps: {
            calendar: "Low",
        },
    },
    {
        id: 10,
        url: "",
        title: "Monthly Checkup",
        start: "2020-12-31T17:00:00.000Z",
        end: "2020-12-31T17:00:00.000Z",
        allDay: true,
        extendedProps: {
            calendar: "Low",
        },
    },
];
