import { AxiosModel } from "models/index";

// prettier-ignore
const server = {
    SIGN_IN: new AxiosModel({method: "POST", url: "apis/auth/signin", typeBody: 4}),
    SIGN_UP: new AxiosModel({method: "POST", url: "apis/auth/signup", typeBody: 2}),
    REQUEST_OTP: new AxiosModel({method: "POST", url: "apis/auth/phone/check", typeBody: 2}),
    VERIFY_OTP: new AxiosModel({method: "POST", url: "apis/auth/phone/verify", typeBody: 2}),
    RESET_PASSWORD_REQUEST_OPT: new AxiosModel({method: "POST", url: "apis/auth/reset", typeBody: 2}),
    RESET_PASSWORD_VERIFY_OPT: new AxiosModel({method: "POST", url: "apis/auth/reset/verify", typeBody: 2}),
    RESET_PASSWORD: new AxiosModel({method: "PATCH", url: "apis/auth/reset", typeBody: 2}),
};

export default server;
