const BigPumpIcon = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt"
            height="512.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none">
                <path
                    d="M3675 4723 c-181 -38 -331 -149 -400 -295 -53 -111 -65 -184 -65
-400 l0 -188 -30 0 c-41 0 -76 -25 -89 -62 -7 -18 -11 -118 -11 -244 l0 -214
-89 0 -89 0 -7 43 c-9 51 -20 73 -54 110 -49 53 -74 60 -231 65 -170 5 -248
-7 -298 -44 -39 -30 -71 -94 -72 -141 l0 -33 -129 0 -129 0 -3 108 c-4 98 -7
114 -37 174 -36 73 -86 123 -161 160 -44 22 -57 23 -261 23 -215 0 -215 0
-271 -28 -66 -32 -132 -99 -162 -165 -17 -36 -23 -74 -27 -161 l-5 -115 -100
-17 c-228 -41 -423 -140 -591 -301 -113 -109 -173 -190 -239 -323 -140 -282
-163 -585 -65 -877 140 -418 513 -727 948 -783 l52 -7 0 -214 0 -213 -501 -3
-501 -3 -29 -33 c-16 -18 -29 -44 -29 -58 0 -33 25 -81 49 -94 13 -7 820 -10
2506 -10 2705 0 2526 -4 2553 55 19 41 14 72 -17 107 l-29 33 -1581 3 -1581 2
2 213 3 212 165 0 165 0 7 -48 c9 -65 47 -120 100 -146 40 -19 61 -21 227 -21
162 0 186 2 221 20 53 27 88 76 101 143 l12 57 714 0 c787 0 750 -3 772 62 7
18 11 117 11 239 l0 209 326 0 326 0 34 34 34 34 0 566 c0 607 0 609 -49 636
-13 6 -141 10 -345 10 l-326 0 0 210 c0 313 3 310 -254 310 l-165 0 -3 236 -3
236 -28 24 c-16 14 -41 24 -58 24 l-29 0 0 145 0 145 601 2 601 3 29 33 29 32
0 231 c0 244 -5 275 -49 299 -13 6 -242 10 -673 9 -539 0 -665 -3 -723 -16z
m1245 -288 l0 -105 -544 0 c-572 0 -581 -1 -644 -47 -60 -45 -66 -69 -72 -263
l-5 -175 -124 -3 -123 -3 4 208 c4 160 8 218 21 251 42 111 135 192 255 223
60 16 130 18 650 18 l582 1 0 -105z m-1140 -955 l0 -160 -250 0 -250 0 0 160
0 160 250 0 250 0 0 -160z m-2071 92 c53 -28 71 -72 71 -169 l0 -83 -260 0
-260 0 0 88 c0 70 4 94 20 120 32 53 65 61 240 62 132 0 160 -3 189 -18z m991
-625 l5 -389 24 -19 c50 -40 109 -34 145 15 20 26 21 43 24 297 l3 269 647 -2
647 -3 3 -952 2 -953 -649 0 -650 0 -3 271 -3 271 -33 29 c-44 40 -93 40 -133
0 l-29 -29 0 -381 0 -381 -130 0 -130 0 0 1175 0 1176 128 -3 127 -3 5 -388z
m-460 -782 l0 -955 -602 0 c-480 0 -617 3 -670 14 -434 93 -744 455 -765 896
-24 504 347 932 862 993 17 2 288 5 603 6 l572 1 0 -955z m2680 -5 l0 -440
-260 0 -260 0 0 440 0 440 260 0 260 0 0 -440z m-3220 -1365 l0 -215 -220 0
-220 0 0 215 0 215 220 0 220 0 0 -215z"
                />
                <path
                    d="M2729 2231 c-75 -76 4 -201 102 -161 40 17 61 50 61 97 0 29 -7 43
-31 65 -43 39 -92 38 -132 -1z"
                />
                <path
                    d="M1558 2731 c-44 -39 -46 -98 -5 -138 27 -28 28 -28 185 -31 91 -2
169 1 184 7 66 25 82 116 29 163 l-31 28 -165 0 -165 0 -32 -29z"
                />
                <path
                    d="M1560 2233 c-40 -37 -47 -78 -20 -121 11 -18 32 -37 46 -42 14 -6 90
-10 170 -10 164 0 196 9 219 63 18 43 9 82 -27 112 -29 24 -33 25 -194 25
l-164 0 -30 -27z"
                />
                <path
                    d="M1560 1733 c-40 -37 -47 -78 -20 -121 11 -18 32 -37 46 -42 14 -6 90
-10 170 -10 164 0 196 9 219 63 18 43 9 82 -27 112 -29 24 -33 25 -194 25
l-164 0 -30 -27z"
                />
            </g>
        </svg>
    );
};

export default BigPumpIcon;
