import axios_app from "https/App";

const httpClient_App = async (Axiosmodel, withCredentials = false) => {
    return await axios_app({
        method: Axiosmodel.method,
        url: Axiosmodel.url,
        config: Axiosmodel,
        withCredentials: withCredentials,
    })
        .then((res) => res)
        .catch((err) => err);
};

export { httpClient_App };
