// ** React Imports
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const VerticalMenuHeader = props => {
  // ** Props
  const { menuCollapsed, setGroupOpen, menuHover } = props

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  return (
    <div className='navbar-header'>
        <ul className='nav navbar-nav flex-row'>
            <li className='nav-item me-auto'>
                <Link to={'/admin'} className="navbar-brand">
                    <span className="brand-logo">
                        <img className="brand-img" src="/assets/img/logo/logo.svg" alt="App Logo" />
                    </span>
                    <h2 className="brand-text">IOT Manage</h2>
                </Link>
            </li>
        </ul>
    </div>
  )
}

export default VerticalMenuHeader
