import {FormFoodingModel, FormCheckYorModel, FormDailyReportModel} from "models";
import { ON_CHANGE_FORM_REPORT, FORM_REPORT_ADD_FOODING, FORM_REPORT_ADD_YOR } from "redux/constants/dialyReport.constant";

const initialState = new FormDailyReportModel();

const FormDailyReportReducer = (state = initialState, action) =>{
    switch (action.type){
        case ON_CHANGE_FORM_REPORT:
            return {...state, [action.payload.name]: action.payload.value};
        case FORM_REPORT_ADD_FOODING:
            return {...state, Fooding: state.Fooding.concat([new FormFoodingModel()]),
            };
        case FORM_REPORT_ADD_YOR:
            return {...state, CheckYor: state.CheckYor.concat([new FormCheckYorModel()]),
            };
        default:
            return state;
    }
};

export default FormDailyReportReducer;