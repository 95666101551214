export const GET_TASKS = "TODO/GET";
export const SET_TASKS = "TODO/SET";

export const ADD_TASK = "TODO/ADD";
export const UPDATE_TASK = "TODO/UPDATE";
export const DELETE_TASK = "TODO/DELETE";

export const GET_SELECTED_TASK = "TODO/TASK/GET";
export const SET_SELECTED_TASK = "TODO/TASK/SET";

export const GET_FILTERS = "TODO/FILTER/GET";
export const SET_FILTERS = "TODO/FILTER/SET";

export const UPDATE_SUCCESS = "TODO/RED/SUCCESS";
export const UPDATE_REDUX_TASK = "TODO/RED/UPDATE";
export const DELETE_REDUX_TASK = "TODO/RED/DELETE";