// ** Axios Imports
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { mockCalendar1, eventInitial } from "./mock";

// export const fetchEvents = createAsyncThunk(
//     "appCalendar/fetchEvents",
//     async (calendars) => {
//         const response = await axios.get("/apps/calendar/events", {
//             calendars,
//         });
//         return response.data;
//     }
// );

// export const addEvent = createAsyncThunk(
//     "appCalendar/addEvent",
//     async (event, { dispatch, getState }) => {
//         await axios.post("/apps/calendar/add-event", { event });
//         await dispatch(fetchEvents(getState().calendar.selectedCalendars));
//         return event;
//     }
// );

export const updateEvent = createAsyncThunk(
    "appCalendar/updateEvent",
    async (event, { dispatch, getState }) => {
        await axios.post("/apps/calendar/update-event", { event });
        await dispatch(fetchEvents(getState().calendar.selectedCalendars));
        return event;
    }
);

export const updateFilter = createAsyncThunk(
    "appCalendar/updateFilter",
    async (filter, { dispatch, getState }) => {
        console.log(filter);
        if (getState().calendar.selectedCalendars.includes(filter)) {
            await dispatch(
                fetchEvents(
                    getState().calendar.selectedCalendars.filter(
                        (i) => i !== filter
                    )
                )
            );
        } else {
            await dispatch(
                fetchEvents([...getState().calendar.selectedCalendars, filter])
            );
        }
        return filter;
    }
);

export const updateAllFilters = createAsyncThunk(
    "appCalendar/updateAllFilters",
    async (value, { dispatch }) => {
        if (value === true) {
            await dispatch(fetchEvents(["High", "Medium", "Low"]));
        } else {
            await dispatch(fetchEvents([]));
        }
        return value;
    }
);

export const removeEvent = createAsyncThunk(
    "appCalendar/removeEvent",
    async (id) => {
        await axios.delete("/apps/calendar/remove-event", { id });
        return id;
    }
);

export const appCalendarSlice = createSlice({
    name: "appCalendar",
    initialState: {
        events: eventInitial,
        selectedEvent: {},
        selectedCalendars: ["High", "Medium", "Low"],
    },
    reducers: {
        selectEvent: (state, action) => {
            state.selectedEvent = action.payload;
        },
        fetchEvents: (state, action) => {
            const response = mockCalendar1.events.filter((event) =>
                action.payload.includes(event.extendedProps.calendar)
            );
            state.events = response;
        },
        addEvent: (state, action) => {
            const event = action.payload;
            const { length } = state.events;
            let lastIndex = 0;
            if (length) {
                lastIndex = state.events[length - 1].id;
            }
            event.id = lastIndex + 1;

            state.events.push(event);
        },
    },
    extraReducers: (builder) => {
        builder
            // .addCase(fetchEvents.fulfilled, (state, action) => {
            //     state.events = action.payload;
            // })
            .addCase(updateFilter.fulfilled, (state, action) => {
                if (state.selectedCalendars.includes(action.payload)) {
                    state.selectedCalendars.splice(
                        state.selectedCalendars.indexOf(action.payload),
                        1
                    );
                } else {
                    state.selectedCalendars.push(action.payload);
                }
            })
            .addCase(updateAllFilters.fulfilled, (state, action) => {
                const value = action.payload;
                let selected = [];
                if (value === true) {
                    selected = ["High", "Medium", "Low"];
                } else {
                    selected = [];
                }
                state.selectedCalendars = selected;
            });
    },
});

export const { selectEvent, fetchEvents, addEvent } = appCalendarSlice.actions;

export default appCalendarSlice.reducer;
