import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";

const SigninPage = lazy(() => import("./authentication/SigninPage"));
const SignupPage = lazy(() => import("./authentication/Signup"));
const ForgetPassword = lazy(() => import("./authentication/ForgetPassword"));
const ChangePassword = lazy(() => import("./authentication/changePassword"));

export const AuthViews = () => {
    return (
        <Suspense fallback={<div>Loading</div>}>
            <Routes>
                <Route
                    path={`${AUTH_PREFIX_PATH}/signin`}
                    element={<SigninPage />}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/signup`}
                    element={<SignupPage />}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/forgetpassword`}
                    element={<ForgetPassword />}
                />
                <Route
                    path={`${AUTH_PREFIX_PATH}/changepassword`}
                    element={<ChangePassword />}
                />
                <Route
                    path={`*`}
                    element={<Navigate to={`${AUTH_PREFIX_PATH}/signin`} />}
                />
            </Routes>
        </Suspense>
    );
};

export default AuthViews;
