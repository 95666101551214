import moment from "moment";
// import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";

export function onMounted(classer = "") {
    //   $('body').removeClass();
    //   $('body').addClass(classer);
    return true;
}

export const AOSOnMounted = (
    init = {
        easing: "ease-in-out-cubic",
        duration: 750,
        once: true,
        offset: 10,
    }
) => {
    AOS.init(init);
};

export function formatNumber(value, digits = 2) {
    let val = (value / 1).toFixed(digits);
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatDate(value, format = "DD/MM/YYYY") {
    let string = moment(new Date(String(value))).format(format);
    if (string) {
        string = string.split("/");
        return `${string[0]}/${string[1]}/${parseInt(string[2]) + 543}`;
    } else {
        return "";
    }
}
export function formatSeconds(sec) {
    if (sec) {
        sec = Math.round(sec);
        let hours = Math.floor(sec / 3600);
        hours >= 1 ? (sec -= hours * 3600) : (hours = "00");
        let min = Math.floor(sec / 60);
        min >= 1 ? (sec -= min * 60) : (min = "00");
        sec < 1 ? (sec = "00") : void 0;

        min.toString().length === 1 ? (min = "0" + min) : void 0;
        sec.toString().length === 1 ? (sec = "0" + sec) : void 0;

        return hours + ":" + min + ":" + sec;
    } else {
        return "00:00:00";
    }
}

export function createOptionsFromArray(arrayInput) {
    return arrayInput.map((element) => {
        return {
            label: element,
            value: element,
        };
    });
}

export function createSelectValueFromValue(input) {
    return { label: input, value: input };
}
