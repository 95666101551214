export class LabShrimpModel {
    constructor(){
        // Lab Shrimp
        this.exampleShrimp = {
            ShrimpWeight : [undefined],
            AVGweight : 0,
        }
        // Shrimp Anomaly
        this.inShrimp = {
            Fat : undefined,
            LeftLiverCell : undefined,
            ConstrictedLiver : undefined,
            YellowLiverTube : undefined,
            SwellLiver : undefined,
            DamagedLiver : undefined,
            Krasuay : undefined,
            GroupFat : undefined,
            Microsporidia : undefined,
            ZooPlanktonBody : undefined,
            ZooPlanktonLeg : undefined,
            ZooPlanktonTail : undefined,
            MucusIntestine : undefined,
            SwellIntestine : undefined,
            dregs : undefined,
            Anomaly : undefined
        }
        // Shrimp Liver Anomally
        this.inLiverShrimp ={
            Purple_Liver : undefined,
            White_Liver : undefined,
            Green_Liver : undefined,
            Total_Liver : 0,
            LiverAnomally_Image : [],
        }
    };
}