import { APP_PREFIX_PATH, FARM_MODULE_PATH } from "configs/AppConfig";
import { alertToast } from "redux/actions/alert.actions";
import {
    SET_FARM_INFO,
    SET_FARM_LIST,
} from "redux/constants/farmModule.constants";
import FarmServices from "services/FarmServices";
import { getPondList } from "./pond.actions";

export const getFarmList = (payload) => async (dispatch, state) => {
    const { status, data } = await FarmServices.getFarmList({
        query: {
            module: "Farm",
        },
    });

    if (status !== 200) {
        return false;
    }

    const tempObject = data.map((v) => {
        console.log(v);
        return {
            ...v,
            link: `/${APP_PREFIX_PATH}/${FARM_MODULE_PATH}/farms/${v._id}`,
        };
    });
    dispatch({
        type: SET_FARM_LIST,
        payload: tempObject,
    });
    return data;
};

export const getFarmDetailByID = (id) => async (dispatch, state) => {
    const { status, data } = await FarmServices.getFarmDetailByID({
        path: {
            farmId: id,
        },
    });

    if (status === 200) {
        dispatch({
            type: SET_FARM_INFO,
            payload: data,
        });
    }
    return data;
};

export const getFarmDetail = (farmId) => async (dispatch, state) => {
    const { status, data } = await FarmServices.getFarmDetailByID({
        path: {
            farmId: farmId,
        },
    });

    if (status !== 200) {
        dispatch(
            alertToast("error", {
                message: "โหลดข้อมูลไม่สำเร็จ",
            })
        );
        return false;
    }
    return data;
};

export const createFarm = (payload) => async (dispatch, state) => {
    const { status, data } = await FarmServices.serviceCreateFarm({
        data: payload,
    });

    if (status !== 200) {
        dispatch(
            alertToast("error", {
                message: "โหลดข้อมูลไม่สำเร็จ",
            })
        );
        return false;
    }
    dispatch(alertToast("success", { message: "สร้างฟาร์มสำเร็จ" }));
    return true;
};

export const updateFarm = (payload, farmId) => async (dispatch, state) => {
    const { status, data } = await FarmServices.serviceEditFarm({
        data: payload,
        path: { farmId: farmId },
    });

    if (status !== 200) {
        dispatch(
            alertToast("error", {
                message: "โหลดข้อมูลไม่สำเร็จ",
            })
        );
        return false;
    }
    dispatch(alertToast("success", { message: "แก้ไขฟาร์มสำเร็จ" }));
    return true;
};

export const deleteFarm = (id) => async (dispatch, state) => {
    const { status } = await FarmServices.serviceDeleteFarm({
        path: {
            farmId: id,
        },
    });
    if (status !== 200) {
        dispatch(
            alertToast("error", {
                message: "ลบฟาร์มไม่สำเร็จ",
            })
        );
        return false;
    }
    dispatch(alertToast("success", { message: "ลบฟาร์มสำเร็จ" }));
    dispatch(getFarmList());
    return true;
};

export const createPond = (payload, farmId) => async (dispatch, state) => {
    const { status, data } = await FarmServices.serviceCreatePond({
        data: payload,
        path: { farmId: farmId },
    });

    if (status !== 200) {
        dispatch(
            alertToast("error", {
                message: "โหลดข้อมูลไม่สำเร็จ",
            })
        );
        return false;
    }
    dispatch(alertToast("success", { message: "สร้างบ่อสำเร็จ" }));
    return data;
};

export const editPond =
    (payload, farmId, pondListId, pondId) => async (dispatch, state) => {
        const { status, data } = await FarmServices.serviceEditPond({
            data: payload.pondType.pondList[0],
            path: { farmId, pondListId, pondId },
        });

        if (status !== 200) {
            dispatch(
                alertToast("error", {
                    message: "โหลดข้อมูลไม่สำเร็จ",
                })
            );
            return false;
        }
        dispatch(alertToast("success", { message: "แก้ไขบ่อสำเร็จ" }));
        return data;
    };

export const connectPond =
    (payload, farmId, pondListId) => async (dispatch, state) => {
        let status = true;
        let i = 0;
        while (i < payload.length) {
            let pond = payload[i];
            let path = {
                farmId: farmId,
                pondListId,
                pondId: pond.pondConnection_pondId,
            };
            let j = 0;
            while (j < pond.connectWith.length) {
                let connectWithId = pond.connectWith[j];
                let result = await FarmServices.serviceConnectPond({
                    data: { connectToPondId: connectWithId },
                    path,
                });
                if (result.status !== 200) {
                    status = false;
                }
                j += 1;
            }
            i += 1;
        }
        if (!status) {
            dispatch(
                alertToast("error", {
                    message: "โหลดข้อมูลไม่สำเร็จ",
                })
            );
            return false;
        }
        dispatch(getPondList(farmId));
        dispatch(alertToast("success", { message: "เชื่อมบ่อสำเร็จ" }));
        return status;
    };
