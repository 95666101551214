import { lazy } from "react";
import { APP_PREFIX_PATH, STOCK_MODULE_PATH } from "configs/AppConfig";
import RouteInventory from "./Inventory";
import RouteWarehouses from "./Warehouses";
import RouteReport from "./Report";
const ProductCategoryPage = lazy(async () => {
    return import("./Category");
});

export const RouteStockModule = {
    path: `${APP_PREFIX_PATH}/${STOCK_MODULE_PATH}`,
    children: [
        {
            index: true,
            path: "dashboard",
            element: <></>,
        },
        {
            path: "invertory",
            children: RouteInventory,
        },
        {
            path: "warehouse",
            children: RouteWarehouses,
        },
        {
            path: "report",
            children: RouteReport,
        },
        {
            index: true,
            path: "category",
            element: <ProductCategoryPage />,
        },
    ],
};
