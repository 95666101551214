import { FarmLicenseModel } from "./stepCreateFarm/formCreateFarmStep2.model";
import {
    GeneratorModel,
    PumpMotorModel,
    PumpPeriodModel,
    PumpStationModel,
    TransformerModel,
} from "./stepCreateFarm/formCreateFarmStep3.model";
import { PondTypeModel } from "./stepCreateFarm/formCreateFarmStep4.model";

export default class FormCreateFarmModel {
    constructor() {
        /* Create farm step1 */
        // Farm detail
        this.farmName = "";
        this.farmRegistrationNumber = "";
        this.farm_farmAreaRai = 0;
        this.farm_farmAreaSquareMeter = 0;
        // Farmer detail
        this.farm_farmerName = "";
        this.farm_farmerRegistrationNumber = "";
        this.farm_farmerPhoneNumber = "";
        // Farm address
        this.farm_address = "";
        this.farm_country = "";
        this.farm_province = "";
        this.farm_district = "";
        this.farm_supDistrict = "";
        this.farm_zipcode = "";
        // Farm's office address
        this.farm_officeAddress = "";
        this.farm_officeCountry = "";
        this.farm_officeProvince = "";
        this.farm_officeDistrict = "";
        this.farm_officeSupDistrict = "";
        this.farm_officeZipcode = "";
        /* Create farm step2 */
        // Licenses
        this.farm_haveLicenses = true;
        this.farm_licenses = [new FarmLicenseModel()];
        /* Create farm step3 */
        // Pump station
        this.pumpStationList = [new PumpStationModel()];
        // Pump period
        this.havePumpPeriod = true;
        this.pumpPeriodUpMoon = [new PumpPeriodModel()];
        this.pumpPeriodDownMoon = [new PumpPeriodModel()];
        // Pump motor
        this.pumpMotorList = [new PumpMotorModel()];
        // Electric system
        this.haveEletricSystem = true;
        this.transformer = [new TransformerModel()];
        // Generator
        this.haveGenerator = true;
        this.generator = [new GeneratorModel()];
        /* Create farm step4 */
        // Pond
        this.pondTypeList = [
            new PondTypeModel("บ่อเลี้ยงกุ้งหลัก"),
            new PondTypeModel("บ่อน้ำพักนอก", "togglable"),
            new PondTypeModel("บ่อน้ำพักดี", "togglable"),
            new PondTypeModel("บ่อเลน", "togglable"),
            new PondTypeModel("บ่อน้ำเสีย", "togglable"),
        ];
        /* Create farm step5 */
        // pond connection
        this.pondConnectionList = [];
    }
    mapAttribute(inputObject) {
        Object.keys(inputObject).forEach((element) => {
            this[element] = inputObject[element];
        });
    }
}

/* ............Step 5............ */
