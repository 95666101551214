export const ACCESS_SAMPLING = {
    I: "access",
    a: "apps.farm_module.sampling.access",
};
export const SAMPLING_REQUISTION_CREATE = {
    I: "post",
    a: "apps.farm_module.sampling.sampling_requisition_form.create",
};
export const SAMPLING_REQUISTION_REJECT = {
    I: "put",
    a: "apps.farm_module.sampling.sampling_requisition_form.reject",
};
export const SAMPLING_HEALTH_REPORT_VIEW_GENERAL_INFO = {
    I: "get",
    a: "apps.farm_module.sampling.health_report.view_general_infomation",
};
export const SAMPLING_HEALTH_REPORT_UPDATE_GENERAL_INFO = {
    I: "put",
    a: "apps.farm_module.sampling.health_report.update_general_infomation",
};
export const SAMPLING_HEALTH_REPORT_VIEW_SHRIMP_WEIGHT = {
    I: "get",
    a: "apps.farm_module.sampling.health_report.view_shrimp_weight",
};
export const SAMPLING_HEALTH_REPORT_UPDATE_SHRIMP_WEIGHT = {
    I: "put",
    a: "apps.farm_module.sampling.health_report.update_shrimp_weight",
};
export const SAMPLING_HEALTH_REPORT_VIEW_SHRIMP_HEALTH = {
    I: "get",
    a: "apps.farm_module.sampling.health_report.view_shrimp_health",
};
export const SAMPLING_HEALTH_REPORT_UPDATE_SHRIMP_HEALTH = {
    I: "put",
    a: "apps.farm_module.sampling.health_report.update_shrimp_health",
};
export const SAMPLING_HEALTH_REPORT_ASK_APPROVAL = {
    I: "put",
    a: "apps.farm_module.sampling.health_report.ask_approval",
};
