// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.misc-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center; }

.misc-wrapper .misc-inner {
  position: relative;
  max-width: 750px; }

.misc-wrapper .brand-logo {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 2rem;
  left: 2rem;
  margin: 0; }

.misc-wrapper .brand-logo .brand-text {
  font-weight: 600; }

@media (max-height: 625px) {
  .misc-wrapper .misc-inner {
    margin-top: 4rem; } }
`, "",{"version":3,"sources":["webpack://./src/css/page-misc.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,mBAAmB;EACnB,uBAAuB,EAAE;;AAE3B;EACE,kBAAkB;EAClB,gBAAgB,EAAE;;AAEpB;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,SAAS,EAAE;;AAEb;EACE,gBAAgB,EAAE;;AAEpB;EACE;IACE,gBAAgB,EAAE,EAAE","sourcesContent":[".misc-wrapper {\n  display: flex;\n  flex-basis: 100%;\n  min-height: 100vh;\n  width: 100%;\n  align-items: center;\n  justify-content: center; }\n\n.misc-wrapper .misc-inner {\n  position: relative;\n  max-width: 750px; }\n\n.misc-wrapper .brand-logo {\n  display: flex;\n  justify-content: center;\n  position: absolute;\n  top: 2rem;\n  left: 2rem;\n  margin: 0; }\n\n.misc-wrapper .brand-logo .brand-text {\n  font-weight: 600; }\n\n@media (max-height: 625px) {\n  .misc-wrapper .misc-inner {\n    margin-top: 4rem; } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
