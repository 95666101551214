import { FARM_MODULE, SAMPLING } from "constants/LocalhostConstant";
import {
    REMOVE_SAMPLING,
    SET_SAMPLING_LIST,
    UPDATE_SAMPLING_STATUS,
} from "redux/constants/farmModule_sampling.constants";
import { getFromLS, saveToLS } from "utility/LocalStorage";

const initialState = {
    samplingList: [],
};

const farmModuleReducer = (
    state = getFromLS(FARM_MODULE, SAMPLING)
        ? getFromLS(FARM_MODULE, SAMPLING)
        : initialState,
    action
) => {
    const { type, payload } = action;
    let changed = state;

    switch (type) {
        case SET_SAMPLING_LIST: {
            changed = {
                ...changed,
                samplingList: payload,
            };
            break;
        }
        case REMOVE_SAMPLING: {
            changed = {
                ...changed,
                samplingList: changed.samplingList.filter(
                    (sampling) => sampling._id !== payload
                ),
            };
            break;
        }
        case UPDATE_SAMPLING_STATUS: {
            changed = {
                ...changed,
                samplingList: changed.samplingList.map((sampling) =>
                    sampling._id === payload.id
                        ? { ...sampling, status: payload.status }
                        : sampling
                ),
            };
            break;
        }
        default:
            break;
    }

    saveToLS(FARM_MODULE, SAMPLING, changed);
    return changed;
};

export default farmModuleReducer;
