import { lazy } from "react";
import { APP_PREFIX_PATH, TODO_MODULE_PATH } from "configs/AppConfig";

const CalendarPage = lazy(async () => {
    return import("./Calendar");
});
const TodoListPage = lazy(async () => {
    return import("./TodoList");
});

export const RouteTodoModule = {
    path: `${APP_PREFIX_PATH}/${TODO_MODULE_PATH}`,
    children: [
        {
            index: true,
            path: "todo-list",
            element: <TodoListPage />,
        },
        {
            index: true,
            path: "schedule",
            element: <CalendarPage />,
        },
    ],
};
