import {
    SET_FARM_INFO,
    SET_FARM_LIST,
    // Pond
    SET_POND_LIST,
    SET_POND_STATUS,
} from "../constants/farmModule.constants";

import { FARM_MANAGEMENT, FARM_MODULE } from "constants/LocalhostConstant";
import {
    AWAITING_PAYMENT,
    FINSIHED,
    HEALTH_CHECK,
    ORDER_ARRIVED,
    ORDER_REJECTION,
    PENDING_ORDER_APPROVAL,
    PENDING_REPORT_APPROVAL,
    REPORT_REJECTION,
} from "constants/Status/FarmModule/AFPD";
import { getFromLS, saveToLS } from "utility/LocalStorage";

const mockData = [
    {
        id: 1,
        topic: `รายการซื้อลูกกุ้ง 12-10-2022`,
        description: `ฟาร์ม A บ่อ A`,
        status: PENDING_ORDER_APPROVAL,
    },
    {
        id: 2,
        topic: `รายการซื้อลูกกุ้ง 12-11-2022`,
        description: `ฟาร์ม A บ่อ A`,
        status: ORDER_REJECTION,
    },
    {
        id: 3,
        topic: `รายการซื้อลูกกุ้ง 12-11-2022`,
        description: `ฟาร์ม A บ่อ A`,
        status: ORDER_ARRIVED,
    },
    {
        id: 4,
        topic: `รายการซื้อลูกกุ้ง 12-11-2022`,
        description: `ฟาร์ม A บ่อ A`,
        status: HEALTH_CHECK,
    },
    {
        id: 5,
        topic: `รายการซื้อลูกกุ้ง 12-11-2022`,
        description: `ฟาร์ม A บ่อ A`,
        status: PENDING_REPORT_APPROVAL,
    },
    {
        id: 6,
        topic: `รายการซื้อลูกกุ้ง 12-11-2022`,
        description: `ฟาร์ม A บ่อ A`,
        status: REPORT_REJECTION,
    },
    {
        id: 7,
        topic: `รายการซื้อลูกกุ้ง 12-11-2022`,
        description: `ฟาร์ม A บ่อ A`,
        status: AWAITING_PAYMENT,
    },
    {
        id: 7,
        topic: `รายการซื้อลูกกุ้ง 12-11-2022`,
        description: `ฟาร์ม A บ่อ A`,
        status: FINSIHED,
    },
];

const initialState = {
    farmSystem: {
        info: {},
        moreDetail: {},
    },
    farm: {
        farmList: [],
        info: {},
        moreDetail: {},
    },
    pond: {
        pondList: [],
    },
    afpd: {
        planningList: mockData,
    },
};

const farmModuleReducer = (
    state = getFromLS(FARM_MODULE, FARM_MANAGEMENT)
        ? getFromLS(FARM_MODULE, FARM_MANAGEMENT)
        : initialState,
    action
) => {
    const { type, payload } = action;
    let changed = state;

    switch (type) {
        case SET_FARM_LIST: {
            changed = {
                ...state,
                farm: {
                    ...state.farm,
                    farmList: payload,
                },
            };
            break;
        }

        case SET_FARM_INFO: {
            changed = {
                ...state,
                farm: {
                    ...state.farm,
                    info: payload,
                },
            };
            break;
        }

        case SET_POND_LIST: {
            changed = {
                ...state,
                pond: {
                    ...state.pond,
                    pondList: payload,
                },
            };
            break;
        }

        case SET_POND_STATUS: {
            changed = {
                ...state,
                pond: {
                    ...state.pond,
                    pondList: state.pond.pondList.map((pond) => {
                        return pond.id === payload.id
                            ? { ...pond, status: payload.status }
                            : pond;
                    }),
                },
            };
            break;
        }

        default:
            break;
    }

    saveToLS(FARM_MODULE, FARM_MANAGEMENT, changed);
    return changed;
};

export default farmModuleReducer;
