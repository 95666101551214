const ZoneIcon = () => {
    return (
        <>
            <svg
                width="56"
                height="40"
                viewBox="0 0 56 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16 11C16 13.875 13.75 16 11 16H5C2.125 16 0 13.875 0 11V5C0 2.25 2.125 0 5 0H11C13.75 0 16 2.25 16 5V11ZM16 35C16 37.875 13.75 40 11 40H5C2.125 40 0 37.875 0 35V29C0 26.25 2.125 24 5 24H11C13.75 24 16 26.25 16 29V35ZM20 5C20 2.25 22.125 0 25 0H31C33.75 0 36 2.25 36 5V11C36 13.875 33.75 16 31 16H25C22.125 16 20 13.875 20 11V5ZM36 35C36 37.875 33.75 40 31 40H25C22.125 40 20 37.875 20 35V29C20 26.25 22.125 24 25 24H31C33.75 24 36 26.25 36 29V35ZM40 5C40 2.25 42.125 0 45 0H51C53.75 0 56 2.25 56 5V11C56 13.875 53.75 16 51 16H45C42.125 16 40 13.875 40 11V5ZM56 35C56 37.875 53.75 40 51 40H45C42.125 40 40 37.875 40 35V29C40 26.25 42.125 24 45 24H51C53.75 24 56 26.25 56 29V35Z"
                    fill="#7367F0"
                />
            </svg>
        </>
    );
};

export default ZoneIcon;
