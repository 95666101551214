export default class FarmDetailModel {
    constructor(data) {
        this.id = data?.id || "";
        this.name = data?.name || `Farm ${this.id}`;
        this.registrationNumber = data?.registrationNumber || "";

        this.country = data?.country || "";
        this.province = data?.province || "";
        this.district = data?.district || "";
        this.subDistrict = data?.subDistrict || "";
        this.address = data?.address || "";

        this.officeCountry = data?.officeCountry || "";
        this.officeProvince = data?.officeProvince || "";
        this.officeDistrict = data?.officeDistrict || "";
        this.officeSubDistrict = data?.officeSubDistrict || "";
        this.officeAddress = data?.officeAddress || "";

        this.areaRai = data?.AreaRai || 0;
        this.areaSquareMeter = data?.areaSquareMeter || 0;

        this.farmerID = data?.farmerID || "";
        this.farmerRegistrationNumber = data?.farmerRegistrationNumber || "";
        this.farmerPhoneNumber = data?.farmerPhoneNumber || "";

        this.haveEletricSystem = data?.haveEletricSystem || false;
        this.haveGenerator = data?.haveGenerator || false;
        this.havePumpPeriod = data?.havePumpPeriod || false;
    }
}