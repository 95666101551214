export class FormCreateFarmStep1Model {
    constructor() {
        // Farm detail
        this.farmName = "";
        this.farmRegistrationNumber = "";
        this.farmAreaRai = 0;
        this.farmAreaSquareMeter = 0;
        // Farmer detail
        this.farmerRegistrationNumber = "";
        this.farmerPhoneNumber = "";
        // Farm address
        this.address = "";
        this.country = "";
        this.province = 0;
        this.district = 0;
        this.subDistrict = 0;
        this.zipcode = "";
        // Farm's office address
        this.officeAddress = "";
        this.officeCountry = "";
        this.officeProvince = "";
        this.officeDistrict = "";
        this.officeSubDistrict = "";
        this.officeZipcode = "";
    }
}
