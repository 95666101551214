/* ............Step 4............ */
export class FormCreateFarmStep4Model {
    constructor() {
        this.pondTypeList = [
            new PondTypeModel("บ่อเลี้ยงกุ้งหลัก"),
            new PondTypeModel("บ่อน้ำพักนอก", "togglable"),
            new PondTypeModel("บ่อน้ำพักดี", "togglable"),
            new PondTypeModel("บ่อเลน", "togglable"),
            new PondTypeModel("บ่อน้ำเสีย", "togglable"),
        ];
    }
}
// Pond type
export class PondTypeModel {
    constructor(name = "", type = "", pondList = []) {
        this.pondTypeName = name;
        this.pondTypeDeletable = type === "deletable" ? true : false;
        this.pondTypeTogglable = type === "togglable" ? true : false;
        this.pondTypeIsOpen = true;
        this.pondTypeOpenAccordion = "0";
        this.pondList = pondList;
    }
}
// Pond
export class PondModel {
    constructor(name = "", data = null) {
        // pond Controller
        this.pondIsOpen = true;
        // pond details
        this.pondName = name;
        this.pondShape = data?.pondShape ? data.pondShape : "square";
        this.pondRadius = data?.pondRadius ? data.pondRadius : 0;
        this.pondSide1 = data?.pondSide1 ? data.pondSide1 : 0;
        this.pondSide2 = data?.pondSide2 ? data.pondSide2 : 0;
        this.pondSide3 = data?.pondSide3 ? data.pondSide3 : 0;
        this.pondSide4 = data?.pondSide4 ? data.pondSide4 : 0;
        this.pondDepth = data?.pondDepth ? data.pondDepth : 0;
        this.pondMinWater = data?.pondMinWater ? data.pondMinWater : 0;
        this.pondMaxWater = data?.pondMaxWater ? data.pondMaxWater : 0;
        // about Yor Bridge
        this.pondHaveYor = data?.pondHaveYor ? data.pondHaveYor : true;
        this.pondYorBridgeList = data?.pondYorBridgeList
            ? data.pondYorBridgeList
            : [new YorBridgeModel()];
        // Other
        this.pondHaveOther = data?.pondHaveOther ? data.pondHaveOther : false;
        this.pondSludgeHoleType = data?.pondSludgeHoleType
            ? data.pondSludgeHoleType
            : "";
        this.pondWaterGateType = data?.pondWaterGateType
            ? data.pondWaterGateType
            : "";
        this.pondBaseType = data?.pondBaseType ? data.pondBaseType : "";
        this.pondSlopeAreaType = data?.pondSlopeAreaType
            ? data.pondSlopeAreaType
            : "";
    }
}
// Pond's Yor
export class YorBridgeModel {
    constructor() {
        this.yorHeight = "";
        this.yorCount = "";
    }
}
