import { lazy } from "react";
import { RoutePond } from "./Pond";

const FarmListPage = lazy(async () => {
    return import("./FarmList");
});

const FarmPage = lazy(async () => {
    return import("./FarmTab");
});
const SettingFarmPage = lazy(async () => {
    return import("./Setting");
});

const PondCreationPage = lazy(async () => {
    return import("../Components/PondCreation");
});

const PondEditingPage = lazy(async () => {
    return import("../Components/PondEditing/EditPondPage");
});

export const RouteFarm = [
    {
        index: true,
        path: "",
        element: <FarmListPage />,
    },
    {
        path: ":farmID",
        children: [
            {
                index: true,
                path: "",
                element: <FarmPage />,
            },
            {
                path: "setting",
                children: [
                    {
                        index: true,
                        path: "",
                        element: <SettingFarmPage />,
                    },
                    {
                        index: true,
                        path: "create-pond",
                        element: <PondCreationPage />,
                    },
                    {
                        index: true,
                        path: "edit-pond",
                        element: <PondEditingPage />,
                    },
                ],
            },
            {
                path: ":pondID",
                children: RoutePond,
            },
        ],
    },
];
