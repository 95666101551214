import { User, Users, Shield } from "react-feather";

import { APP_PREFIX_PATH, ROLE_MODULE_PATH } from "configs/AppConfig";

const PREFIX = `${APP_PREFIX_PATH}/${ROLE_MODULE_PATH}`;

/* eslint-disable */
export default [
    {
        header: "การจัดการผู้ใช้",
    },
    {
        id: "list-user",
        title: "ผู้ใช้งาน",
        icon: <User />,
        navLink: `${PREFIX}/user`,
    },
    {
        id: "roles",
        title: "บทบาท",
        icon: <Shield />,
        navLink: `${PREFIX}/role`,
    },
];
