export const INACTIVE = "inactive";
export const ACTIVE = "active";
export const PREPARING = "preparing";
export const FARMING = "farming";

export const POND_STATUS = {};

POND_STATUS[INACTIVE] = {
    color: "red",
    label: "ไม่พร้อมใช้งาน",
};

POND_STATUS[ACTIVE] = {
    color: "green",
    label: "พร้อมใช้งาน",
};

POND_STATUS[PREPARING] = {
    color: "blue",
    label: "กำลังเตรียมบ่อ",
};

POND_STATUS[FARMING] = {
    color: "primary",
    label: "กำลังเลี้ยง",
};
