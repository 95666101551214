export const saveToLS = (keyLS, key, value) => {
    let ls = JSON.parse(global.localStorage.getItem(`${keyLS}`)) || {};
    ls[key] = value;
    if (global.localStorage)
        global.localStorage.setItem(`${keyLS}`, JSON.stringify(ls));
};

export const getFromLS = (keyLS, key) => {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem(`${keyLS}`)) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
};

export const removeLS = (keyLS, key) => {
    let ls = JSON.parse(global.localStorage.getItem(`${keyLS}`)) || {};
    delete ls[key];
    if (global.localStorage)
        global.localStorage.setItem(`${keyLS}`, JSON.stringify(ls));
};

export const removeGlobalLS = (key) => {
    if (global.localStorage) global.localStorage.removeItem(`${key}`);
};

export const removeListLS = (listKey) => {
    if (global.localStorage)
        listKey.forEach((eachKey) => {
            global.localStorage.removeItem(`${eachKey}`);
        });
};
