const CalendarIcon = (props) => {
    const { size, color } = props;
    return (
        <>
            <svg
                width={size}
                height={size}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.5625 10C12.0803 10 12.5 9.58027 12.5 9.0625C12.5 8.54473 12.0803 8.125 11.5625 8.125C11.0447 8.125 10.625 8.54473 10.625 9.0625C10.625 9.58027 11.0447 10 11.5625 10Z"
                    fill={color}
                />
                <path
                    d="M14.6875 10C15.2053 10 15.625 9.58027 15.625 9.0625C15.625 8.54473 15.2053 8.125 14.6875 8.125C14.1697 8.125 13.75 8.54473 13.75 9.0625C13.75 9.58027 14.1697 10 14.6875 10Z"
                    fill={color}
                />
                <path
                    d="M11.5625 13.125C12.0803 13.125 12.5 12.7053 12.5 12.1875C12.5 11.6697 12.0803 11.25 11.5625 11.25C11.0447 11.25 10.625 11.6697 10.625 12.1875C10.625 12.7053 11.0447 13.125 11.5625 13.125Z"
                    fill={color}
                />
                <path
                    d="M14.6875 13.125C15.2053 13.125 15.625 12.7053 15.625 12.1875C15.625 11.6697 15.2053 11.25 14.6875 11.25C14.1697 11.25 13.75 11.6697 13.75 12.1875C13.75 12.7053 14.1697 13.125 14.6875 13.125Z"
                    fill={color}
                />
                <path
                    d="M5.3125 13.125C5.83027 13.125 6.25 12.7053 6.25 12.1875C6.25 11.6697 5.83027 11.25 5.3125 11.25C4.79473 11.25 4.375 11.6697 4.375 12.1875C4.375 12.7053 4.79473 13.125 5.3125 13.125Z"
                    fill={color}
                />
                <path
                    d="M8.4375 13.125C8.95527 13.125 9.375 12.7053 9.375 12.1875C9.375 11.6697 8.95527 11.25 8.4375 11.25C7.91973 11.25 7.5 11.6697 7.5 12.1875C7.5 12.7053 7.91973 13.125 8.4375 13.125Z"
                    fill={color}
                />
                <path
                    d="M5.3125 16.25C5.83027 16.25 6.25 15.8303 6.25 15.3125C6.25 14.7947 5.83027 14.375 5.3125 14.375C4.79473 14.375 4.375 14.7947 4.375 15.3125C4.375 15.8303 4.79473 16.25 5.3125 16.25Z"
                    fill={color}
                />
                <path
                    d="M8.4375 16.25C8.95527 16.25 9.375 15.8303 9.375 15.3125C9.375 14.7947 8.95527 14.375 8.4375 14.375C7.91973 14.375 7.5 14.7947 7.5 15.3125C7.5 15.8303 7.91973 16.25 8.4375 16.25Z"
                    fill={color}
                />
                <path
                    d="M11.5625 16.25C12.0803 16.25 12.5 15.8303 12.5 15.3125C12.5 14.7947 12.0803 14.375 11.5625 14.375C11.0447 14.375 10.625 14.7947 10.625 15.3125C10.625 15.8303 11.0447 16.25 11.5625 16.25Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.625 1.875C5.625 1.52982 5.34518 1.25 5 1.25C4.65482 1.25 4.375 1.52982 4.375 1.875V2.5H3.75C2.36929 2.5 1.25 3.61929 1.25 5V16.25C1.25 17.6307 2.36929 18.75 3.75 18.75H16.25C17.6307 18.75 18.75 17.6307 18.75 16.25V5C18.75 3.61929 17.6307 2.5 16.25 2.5H15.625V1.875C15.625 1.52982 15.3452 1.25 15 1.25C14.6548 1.25 14.375 1.52982 14.375 1.875V2.5H5.625V1.875ZM16.25 3.75C16.9404 3.75 17.5 4.30964 17.5 5V5.625H2.5V5C2.5 4.30964 3.05964 3.75 3.75 3.75H16.25ZM2.5 6.875V16.25C2.5 16.9404 3.05964 17.5 3.75 17.5H16.25C16.9404 17.5 17.5 16.9404 17.5 16.25V6.875H2.5Z"
                    fill={color}
                />
            </svg>
        </>
    );
};

CalendarIcon.defaultProps = {
    size: 20,
    color: "#1F1F1F",
};

export default CalendarIcon;
