import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateWindowWidth } from "redux/actions/general.actions";

import AuthLayout from "layouts/auth-layout";
import AdminLayout from "layouts/admin-layout";

import { UserModel } from "models";

export const Views = () => {
    const dispatch = useDispatch();
    const user = new UserModel(useSelector(({ user }) => user));

    /* eslint-disable */
    useEffect(() => {
        window.addEventListener("resize", () => {
            dispatch(updateWindowWidth(window.innerWidth));
        });
    }, [updateWindowWidth]);
    /* eslint-enable */

    return (
        <>
            {user.isSignedIn() ? (
                <>
                    <AdminLayout />
                </>
            ) : (
                <>
                    <AuthLayout />
                </>
            )}
        </>
    );
};

export default Views;
