export default class UserModel {
    constructor(data) {
        this.authId = data?.id;
        this.uid = data?.uid;
        this.username = data?.username;
        this.firstname = data.firstname ? data.firstname : null;
        this.lastname = data.lastname ? data.lastname : null;
        this.email = data?.email;
        this.avatar = data.avatar
            ? data.avatar
            : "/assets/img/avatar/default.jpg";
        this.role = data?.role || {
            create: [],
            read: [],
            update: [],
            destroy: [],
            feConfig: [],
        };
    }

    displayName = () => {
        if (this.firstname || this.lastname)
            return this.firstname + " " + this.lastname;
        else if (this.username) return `Mr. ${this.username}`;
        else return "General User";
    };

    displayAvatar = () => {
        return this.avatar;
    };

    isSignedIn = () => {
        if (this.uid) return true;
        else return false;
    };
}
