import {
    CLIENT_IP_UPDATE,
    SIDENAV_ACTIVE_INDEX,
    THEME_UPDATE,
    WINDOW_WIDTH,
} from "redux/constants/general.constants";
import { saveToLS, getFromLS } from "utility/LocalStorage";
import { GENERAL } from "constants/LocalhostConstant";

const initialState = {
    clientIp: null,
    sidenavActiveIndex: 0,
    theme: 0,
    width: window.innerWidth,
};

const generalReducer = (
    state = getFromLS(GENERAL, GENERAL)
        ? getFromLS(GENERAL, GENERAL)
        : initialState,
    action
) => {
    const { type, payload } = action;
    let changed = state;

    switch (type) {
        case CLIENT_IP_UPDATE: {
            changed = {
                ...state,
                clientIp: payload,
            };
            break;
        }

        case SIDENAV_ACTIVE_INDEX: {
            changed = {
                ...state,
                sidenavActiveIndex: payload,
            };
            break;
        }

        case THEME_UPDATE: {
            changed = {
                ...state,
                theme: payload,
            };
            break;
        }

        case WINDOW_WIDTH: {
            changed = {
                ...state,
                width: payload,
            };
            break;
        }

        default:
            break;
    }

    saveToLS(GENERAL, GENERAL, changed);
    return changed;
};

export default generalReducer;
