import { ADD_LICENSE, CHANGE_LICENSE_STATUS, EDIT_LICENSE } from "redux/constants/license.constant";
import { EXPIRED, 
    IN_PROGRESS_GREEN, 
    IN_PROGRESS_YELLOW, 
    IN_PROGRESS_ORANGE, 
    IN_PROGRESS_RED,
    DISABLED } from "constants/Status/LicenseModule";

const mockData = [
    {
        id: 1,
        lebel: 'ใบรับรอง',
        category: 'ใบรับรองมาตรฐาน',
        expiredate: new Date('2023-02-23'),
        farm: 'Happy',
        Owner: 'tatar',
        licenseNumber: '1234567890',
        image: undefined,
        status: EXPIRED,
        isEnabled: true,
    },
    {
        id: 2,
        lebel: 'ใบรับรอง',
        category: 'ใบรับรองมาตรฐาน',
        expiredate: new Date('2023-02-24'),
        farm: 'Happy',
        Owner: 'tatar',
        licenseNumber: '1234567890',
        image: undefined,
        status: IN_PROGRESS_GREEN,
        isEnabled: true,
    },
    {
        id: 3,
        lebel: 'ใบรับรอง',
        category: 'ใบรับรองมาตรฐาน',
        expiredate: new Date('2023-02-25'),
        farm: 'Happy',
        Owner: 'tatar',
        licenseNumber: '1234567890',
        image: undefined,
        status: IN_PROGRESS_YELLOW,
        isEnabled: true,
    },
    {
        id: 4,
        lebel: 'ใบรับรอง',
        category: 'ใบรับรองมาตรฐาน',
        expiredate: new Date('2023-02-26'),
        farm: 'Happy',
        Owner: 'tatar',
        licenseNumber: '1234567890',
        image: undefined,
        status: IN_PROGRESS_ORANGE,
        isEnabled: true,
    },
    {
        id: 5,
        lebel: 'ใบรับรอง',
        category: 'ใบรับรองมาตรฐาน',
        expiredate: new Date('2023-02-27'),
        farm: 'Happy',
        Owner: 'tatar',
        licenseNumber: '1234567890',
        image: undefined,
        status: IN_PROGRESS_RED,
        isEnabled: true,
    },
    {
        id: 6,
        lebel: 'ใบรับรอง',
        category: 'ใบรับรองมาตรฐาน',
        expiredate: new Date('2023-02-27'),
        farm: 'Happy',
        Owner: 'tatar',
        licenseNumber: '1234567890',
        image: undefined,
        status: IN_PROGRESS_RED,
        isEnabled: false,
    },
]

const initialState = {
    licenseList: mockData
};

const LicenseModuleReducer = (state = initialState, action) =>{
    const { payload } = action;
    let changed = state;

    switch (action.type){
        case CHANGE_LICENSE_STATUS :{
            const { id, status } = payload;
            console.log(payload)
            let newList = state.licenseList;
            console.log(newList)
            for (var index in newList) {
                if (newList[index].id === id) {
                    newList[index].status = status;
                    break;
                }
            }
            console.log(newList)
            changed = {
                ...state,
                licenseList: newList,
            };
        }

        case ADD_LICENSE :{
            let newList = state.licenseList;
            newList.push(payload)
            changed = {
                ...state,
                licenseList: newList,
            };
        }
        case EDIT_LICENSE :{
            const { id, lebel, expiredate } = payload;
            let newList = state.licenseList;
            for (var index in newList) {
                if (newList[index].id === id) {
                    newList[index].lebel = lebel;
                    newList[index].expiredate = expiredate;
                    break;
                }
            }
            changed = {
                ...state,
                licenseList: newList,
            };
        }
    }
    return changed;
};

export default LicenseModuleReducer;