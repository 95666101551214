export const DB_TLO = "Dashboard_TypeLayout"; // Dashboard_TypeLayout
export const DB_OR_LO = "Dashboard_Original_Layout"; // Dashboard_Original_Layout
export const DB_LI_LO = "Dashboard_List_Layout"; // Dashboard_List_Layout
export const DB_EDIT = "Dashboard_EDIT_layout"; // Dashboard_EDIT_LAYOUT

export const SETTING = "SETTING";
export const GENERAL = "GENERAL";

export const FARM_MODULE = "FARM_MODULE";
export const FARM_MANAGEMENT = "FARM_MANAGEMENT";
export const AFPD = "AFPD";
export const SAMPLING = "SAMPLING";
export const CATCHING = "CATCHING";
export const APD = "APD";

export const TO_DO_LIST_MODULE = "TODO_LIST_MODULE";
export const TO_DO_LIST_MANAGEMENT = "TO_DO_LIST_MANAGEMENT";

export const STOCK_MODULE = "STOCK_MODULE";
export const WHAREHOUSE = "WAREHOUSE";
