import axios from "axios";
import { apiHeaderFormData } from "helpers/header";
import { alertToast } from "redux/actions/alert.actions";
import { APP_URL } from "constants/variables";
import store from "redux/store";

const { dispatch, getState } = store;

const service = axios.create({
    baseURL: APP_URL,
    timeout: 10000,
    withCredentials: true,
});

// prettier-ignore
service.interceptors.request.use((request) => {
    const Config = request.config;

    if (Config?.path !== undefined) {
        Object.keys(Config.path).forEach(item => {
            request.url = request.url.replace(`:${item}`, Config.path[item])
        })
    }

    if (Config?.query !== undefined && Config?.query !== null) {
        let query = `?`;
        Object.keys(Config.query).forEach((value, index) => {
            query = query.concat(
                `${value}=${Config.query[value]}`,
                `${
                    parseInt(index) !== Object.keys(Config.query).length - 1 ? "&" : ""
                }`
            )
        });

        Config.query = query;

        request.url = request.url + Config.query;
    }

    if (Config?.data !== undefined && Config?.data !== null) request.data = Config.data

    if (Config?.typeBody) request.headers = apiHeaderFormData(Config.typeBody);

    return request;
});

service.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response !== undefined) {
            if (error.response.status === 403) {
                if (getState().user.isSignedIn()) {
                    dispatch(
                        alertToast("error", {
                            message: `Token expired.\n Please sign in again.`,
                        })
                    );
                }
                return error.response;
            }
            return error.response;
        }
        return Promise.reject(error);
    }
);

export default service;
