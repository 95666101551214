import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";

import { APP_PREFIX_PATH, REPAIR_MODULE_PATH } from "configs/AppConfig";

const PREFIX = `${APP_PREFIX_PATH}/${REPAIR_MODULE_PATH}`;

/* eslint-disable */
export default [
    {
        header: "ซ่อมอุปกรณ์",
    },
    {
        id: "repair_module-breakitem",
        title: "รายการอุปกรณ์",
        icon: <FormatListBulletedOutlinedIcon />,
        navLink: `${PREFIX}/breakitem`,
    },
];
