import {
    FarmDetailModel
} from "models";

export default class FarmModel {
    constructor(data) {
        this.id = data?.id || "";
        this.farmDetail = data?.farmDetail || new FarmDetailModel({});
        this.ponds = data?.ponds || [];
        this.licenses = data?.licenses || []; // License Model
        this.bankingList = data?.bankingList || []; // Banking Model
        this.pumpStationsIn = data?.pumpStationsIn || [];
        this.pumpStationsOut = data?.pumpStationsOut || [];
        this.pumpPeriodUpMoon = data?.pumpPeriodUpMoon || [];
    }
}