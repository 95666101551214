// ** React Imports
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ** Third Party Components
import classnames from "classnames";

// ** Vertical Menu Components
import VerticalMenuHeader from "./VerticalMenuHeader";
import VerticalMenuNavItems from "./VerticalMenuNavItems";

import menuData from "constants/navigation";

function Sidebar(props) {
    // ** Props
    const { menuCollapsed } = props;

    // ** States
    const [groupOpen, setGroupOpen] = useState([]);
    const [groupActive, setGroupActive] = useState([]);
    const [currentActiveGroup, setCurrentActiveGroup] = useState([]);
    const [activeItem, setActiveItem] = useState(null);

    // ** Menu Hover State
    const [menuHover, setMenuHover] = useState(false);

    // ** Function to handle Mouse Enter
    const onMouseEnter = () => {
        setMenuHover(true);
    };

    return (
        <Fragment>
            <div
                className={classnames(
                    "main-menu menu-fixed menu-accordion menu-shadow",
                    {
                        expanded: menuHover || menuCollapsed === false,
                        "menu-light":
                            props.theme !== "semi-dark" &&
                            props.theme !== "dark",
                        "menu-dark":
                            props.theme === "semi-dark" ||
                            props.theme === "dark",
                    }
                )}
                onMouseEnter={onMouseEnter}
                onMouseLeave={() => setMenuHover(false)}>
                <Fragment>
                    <VerticalMenuHeader menuHover={menuHover} {...props} />
                    <div className="main-menu-content">
                        <ul className="navigation navigation-main">
                            <VerticalMenuNavItems
                                items={menuData}
                                menuHover={menuHover}
                                groupOpen={groupOpen}
                                activeItem={activeItem}
                                groupActive={groupActive}
                                setGroupOpen={setGroupOpen}
                                menuCollapsed={menuCollapsed}
                                setActiveItem={setActiveItem}
                                setGroupActive={setGroupActive}
                                currentActiveGroup={currentActiveGroup}
                                setCurrentActiveGroup={setCurrentActiveGroup}
                            />
                        </ul>
                    </div>
                </Fragment>
            </div>
        </Fragment>
    );
}

Sidebar.defaultProps = {};

Sidebar.propTypes = {
    menuCollapsed: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    theme: state.general.theme,
});

export default connect(mapStateToProps, {})(Sidebar);
