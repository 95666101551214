const NewMoonIcon = () => {
    return (
        <>
            <svg
                width="39"
                height="42"
                viewBox="0 0 39 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.5 21C0.5 9.46875 9.875 0 21.4062 0C22.5312 0 24.2188 0.1875 25.25 0.375C26.1875 0.5625 26.375 1.78125 25.5312 2.25C20.4688 5.15625 17.2812 10.5938 17.2812 16.5C17.2812 26.8125 26.5625 34.5938 36.7812 32.7188C37.7188 32.5312 38.2812 33.5625 37.7188 34.3125C33.7812 39.0938 27.875 42 21.4062 42C9.875 42 0.5 32.625 0.5 21Z"
                    fill="#343536"
                />
            </svg>
        </>
    );
};

export default NewMoonIcon;
