export class WarehouseModel {
    constructor(data) {
        this.id = data?.id || "";
        this.type = data?.type || undefined;
        this.name = data?.name || "-";
        this.description = data?.description || "-";
        this.status = data?.status || undefined;
    }
}

export default WarehouseModel;
