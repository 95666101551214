import server from "./constants/Farm";
import { constAxios } from "./generalFunction";
import { httpClient_App as httpClient } from "https/List";

const FarmServices = {};

FarmServices.getFarmList = (payload) =>
    httpClient(
        constAxios({
            service: server.GET_FARM_LIST,
            payload: payload,
        }),
        true
    );

FarmServices.getFarmDetailByID = (payload) =>
    httpClient(
        constAxios({
            service: server.GET_FARM_DETAIL_BY_ID,
            payload: payload,
        }),
        true
    );

FarmServices.serviceCreateFarm = (payload) =>
    httpClient(
        constAxios({
            service: server.FARM_CREATE,
            payload: payload,
        }),
        true
    );

FarmServices.serviceEditFarm = (payload) =>
    httpClient(
        constAxios({
            service: server.FARM_EDIT,
            payload: payload,
        }),
        true
    );

FarmServices.serviceDeleteFarm = (payload) =>
    httpClient(
        constAxios({
            service: server.FARM_DELETE,
            payload: payload,
        }),
        true
    );

FarmServices.getPondList = (payload) =>
    httpClient(
        constAxios({
            service: server.GET_POND_LIST,
            payload: payload,
        }),
        true
    );

FarmServices.getPondTypeList = (payload) =>
    httpClient(
        constAxios({
            service: server.GET_POND_TYPE_LIST,
            payload: payload,
        }),
        true
    );

FarmServices.getPondDetail = (payload) =>
    httpClient(
        constAxios({
            service: server.GET_POND_DETAIL,
            payload: payload,
        }),
        true
    );

FarmServices.deletePond = (payload) =>
    httpClient(
        constAxios({
            service: server.DELETE_POND,
            payload: payload,
        }),
        true
    );

FarmServices.serviceCreatePond = (payload) =>
    httpClient(
        constAxios({
            service: server.POND_CREATE,
            payload: payload,
        }),
        true
    );

FarmServices.serviceEditPond = (payload) =>
    httpClient(
        constAxios({
            service: server.POND_EDIT,
            payload: payload,
        }),
        true
    );

FarmServices.serviceConnectPond = (payload) =>
    httpClient(
        constAxios({
            service: server.POND_CONNECT,
            payload: payload,
        }),
        true
    );

FarmServices.serviceEditConnectPond = (payload) =>
    httpClient(
        constAxios({
            service: server.POND_CONNECT_EDIT,
            payload: payload,
        }),
        true
    );

FarmServices.createCatchingSchedule = (payload) =>
    httpClient(
        constAxios({
            service: server.CATCHING_SCHEDULE_CREATE,
            payload: payload,
        }),
        true
    );

FarmServices.createCatchingReport = (payload) =>
    httpClient(
        constAxios({
            service: server.CATCHING_REPORT_CREATE,
            payload: payload,
        }),
        true
    );

FarmServices.createSamplingReport = (payload) =>
    httpClient(
        constAxios({
            service: server.SAMPLING_REPORT_CREATE,
            payload: payload,
        }),
        true
    );

FarmServices.createGeneralReport = (payload) =>
    httpClient(
        constAxios({
            service: server.CREATE_GENERAL_REPORT,
            payload: payload,
        }),
        true
    );

FarmServices.readGeneralReport = (payload) =>
    httpClient(
        constAxios({
            service: server.READ_GENERAL_REPORT,
            payload: payload,
        }),
        true
    );

FarmServices.updateGeneralReport = (payload) =>
    httpClient(
        constAxios({
            service: server.UPDATE_GENERAL_REPORT,
            payload: payload,
        }),
        true
    );

FarmServices.createLabShrimp = (payload) =>
    httpClient(
        constAxios({
            service: server.CREATE_LABSHRIMP_REPORT,
            payload: payload,
        }),
        true
    );

FarmServices.readLabShrimp = (payload) =>
    httpClient(
        constAxios({
            service: server.READ_LABSHRIMP_REPORT,
            payload: payload,
        }),
        true
    );

FarmServices.updateLabShrimp = (payload) =>
    httpClient(
        constAxios({
            service: server.UPDATE_LABSHRIMP_REPORT,
            payload: payload,
        }),
        true
    );

FarmServices.createLabWater = (payload) =>
    httpClient(
        constAxios({
            service: server.CREATE_LABWATER_REPORT,
            payload: payload,
        }),
        true
    );

FarmServices.readLabWater = (payload) =>
    httpClient(
        constAxios({
            service: server.READ_LABWATER_REPORT,
            payload: payload,
        }),
        true
    );

FarmServices.updateLabWater = (payload) =>
    httpClient(
        constAxios({
            service: server.UPDATE_LABWATER_REPORT,
            payload: payload,
        }),
        true
    );

FarmServices.getProgressReport = (payload) =>
    httpClient(
        constAxios({
            service: server.GET_PROGRESS,
            payload: payload,
        }),
        true
    );

FarmServices.getProgressMonth = (payload) =>
    httpClient(
        constAxios({
            service: server.GET_PROGRESS_MONTH,
            payload: payload,
        }),
        true
    );
    
FarmServices.getReportList = (payload) =>
    httpClient(
        constAxios({
            service: server.GET_REPORT_LIST,
            payload: payload,
        }),
        true
    );
    
export default FarmServices;
