import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { Settings } from "react-feather";
import { APP_PREFIX_PATH, LICENSE_MODULE_PATH } from "configs/AppConfig";
import {
    ACCESS_LICENSE,
    ACCESS_LICENSE_SETTING,
} from "constants/ability/license-module/license";

const PREFIX = `${APP_PREFIX_PATH}/${LICENSE_MODULE_PATH}`;

/* eslint-disable */
export default [
    {
        header: "ใบอนุญาต",
    },
    {
        id: "license-module",
        title: "รายการใบอนุญาต",
        icon: <FormatListBulletedOutlinedIcon />,
        navLink: `${PREFIX}/List`,
        ability: ACCESS_LICENSE,
    },
    {
        id: "license-setting",
        title: "Setting",
        icon: <Settings />,
        navLink: `${PREFIX}/setting`,
        ability: ACCESS_LICENSE_SETTING,
    },
];
