export const APP_PREFIX_PATH = "admin";
export const AUTH_PREFIX_PATH = "auth";

export const DASHBOARD_MODULE_PATH = "dashboard";
export const ROLE_MODULE_PATH = "user-management";
export const FARM_MODULE_PATH = "farm";
export const TODO_MODULE_PATH = "todo";

export const STOCK_MODULE_PATH = "stock";

export const REPAIR_MODULE_PATH = "repair";
export const LICENSE_MODULE_PATH = "license";
export const NOTI_MODULE_PATH = "noti";
export const ATTEND_MODULE_PATH = "attend";
