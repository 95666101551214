import { lazy } from "react";

const RoleListPage = lazy(() => import("./role-list"));
const RoleDetailPage = lazy(() => import("./role-detail"));

const RouteRole = [
    {
        index: true,
        path: "",
        element: <RoleListPage />,
    },
    {
        index: true,
        path: ":action/:roleID",
        element: <RoleDetailPage />,
    },
];

export default RouteRole;
