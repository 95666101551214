import React, { useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export function Dashboard() {
    const [compactType] = useState("vertical");
    const [layout, setlayout] = useState([
        { i: "a", x: 0, y: 0, w: 1, h: 2 },
        { i: "b", x: 1, y: 0, w: 3, h: 2 },
        { i: "c", x: 4, y: 0, w: 1, h: 2 },
        { i: "d", x: 0, y: 2, w: 1, h: 2 },
    ]);

    const onDrop = (elemParams) => {
        setlayout(elemParams);
    };

    const onLayoutChange = (elemParams) => {
        console.log(elemParams);
        setlayout(elemParams);
    };

    return (
        <div>
            <ResponsiveReactGridLayout
                rowHeight={30}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                layout={layout}
                onLayoutChange={onLayoutChange}
                onDrop={onDrop}
                measureBeforeMount={false}
                compactType={compactType}
                preventCollision={!compactType}
                isDroppable={true}
                droppingItem={{ i: "xx", h: 50, w: 250 }}
            >
                {layout.map((itm, i) => (
                    <div key={itm.i} data-grid={itm} className="block">
                        {i}
                    </div>
                ))}
            </ResponsiveReactGridLayout>
        </div>
    );
}
