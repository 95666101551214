import { lazy } from "react";
import { APP_PREFIX_PATH, REPAIR_MODULE_PATH } from "configs/AppConfig";

const RepairPage = lazy(async () => {
    return import("./ItemList");
});

export const RouteRepairModule = {
    path: `${APP_PREFIX_PATH}/${REPAIR_MODULE_PATH}`,
    children: [
        {
            index: true,
            path: "breakitem",
            element: <RepairPage />,
        },
    ],
};
