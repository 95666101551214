import { lazy } from "react";

const CatchingList = lazy(async () => {
    return import("./CatchingList");
});
const CatchingSchedulePage = lazy(async () => {
    return import("./Schedule");
});
const CatchingReportPage = lazy(async () => {
    return import("./Report");
});

export const Catching_Route = [
    {
        index: true,
        path: "",
        element: <CatchingList />,
    },
    {
        path: "schedule",
        children: [
            {
                index: true,
                path: ":state",
                element: <CatchingSchedulePage />,
            },
            {
                index: true,
                path: ":state/:id",
                element: <CatchingSchedulePage />,
            },
        ],
    },
    {
        path: "report",
        children: [
            {
                index: true,
                path: ":state",
                element: <CatchingReportPage />,
            },
            {
                index: true,
                path: ":state/:id",
                element: <CatchingReportPage />,
            },
        ],
    },
];
