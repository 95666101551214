export const ACCESS_AFPD = {
    I: "access",
    a: "apps.farm_module.afpd.access",
};
export const AFPD_VIEW_GENERAL_INFO = {
    I: "get",
    a: "apps.farm_module.afpd.health_report.view_general_information",
};
export const AFPD_UPDATE_GENERAL_INFO = {
    I: "put",
    a: "apps.farm_module.afpd.health_report.update_general_information",
};
export const AFPD_VIEW_HASH_CHERRY = {
    I: "get",
    a: "apps.farm_module.afpd.health_report.view_hash_cherry",
};
export const AFPD_UPDATE_HASH_CHERRY = {
    I: "put",
    a: "apps.farm_module.afpd.health_report.update_hash_cherry",
};
export const AFPD_VIEW_SHRIMP_HEALTH = {
    I: "get",
    a: "apps.farm_module.afpd.health_report.view_shrimp_health",
};
export const AFPD_UPDATE_SHRIMP_HEALTH = {
    I: "put",
    a: "apps.farm_module.afpd.health_report.update_shrimp_health",
};
export const AFPD_ASK_APPROVAL = {
    I: "put",
    a: "apps.farm_module.afpd.health_report.ask_approval",
};
export const AFPD_REQUISITION_ASK_APPROVAL = {
    I: "put",
    a: "apps.farm_module.afpd.afpd_requisition_form.ask_approval",
};
export const AFPD_VIEW_PAYMENT = {
    I: "get",
    a: "apps.farm_module.afpd.patment.view_patment",
};
export const AFPD_UPDATE_PAYMENT = {
    I: "get",
    a: "apps.farm_module.afpd.patment.update_patment",
};
export const AFPD_REQUISITION_VIEW = {
    I: "get",
    a: "apps.farm_module.afpd.afpd_requisition_form.view",
};
export const AFPD_REQUISITION_CREATE = {
    I: "post",
    a: "apps.farm_module.afpd.afpd_requisition_form.create",
};
