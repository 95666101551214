import {
  PieChart,
  Circle,
} from "react-feather";

import { APP_PREFIX_PATH, DASHBOARD_MODULE_PATH } from "configs/AppConfig";

const PREFIX = `${APP_PREFIX_PATH}/${DASHBOARD_MODULE_PATH}`;

/* eslint-disable */
export default [
  {
    id: 'dashboards',
    title: 'Dashboards',
    icon: <PieChart />,
    children: [
      {
        id: 'general-dashboard',
        title: 'Gemeral Dashboard',
        icon: <Circle size={12} />,
        navLink: `${PREFIX}/general`,
      },
      {
        id: 'custom-dashboard',
        title: 'Custom Dashboard',
        icon: <Circle size={12} />,
        navLink: `${PREFIX}/custom/Profile1`,
      }
    ]
  }
]
