import { lazy } from "react";

const SamplingList = lazy(async () => {
    return import("./SamplingList");
});
const SamplingSchedulePage = lazy(async () => {
    return import("./Schedule");
});
const SamplingReportPage = lazy(async () => {
    return import("./Report");
});

export const Sampling_Route = [
    {
        index: true,
        path: "",
        element: <SamplingList />,
    },
    {
        path: "schedule",
        children: [
            {
                index: true,
                path: ":state",
                element: <SamplingSchedulePage />,
            },
            {
                index: true,
                path: ":state/:id",
                element: <SamplingSchedulePage />,
            },
        ],
    },
    {
        path: "report",
        children: [
            {
                index: true,
                path: ":state",
                element: <SamplingReportPage />,
            },
            {
                index: true,
                path: ":state/:id",
                element: <SamplingReportPage />,
            },
        ],
    },
];
