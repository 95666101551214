export class FormFoodingModel {
    // Fooding
    constructor(){
        this.foodNumber = "";
        this.foodVolume = "";
    }
}

export  class FormCheckYorModel{
    // Check Yor
    constructor(){
        this.FoodLeftover = "";
        this.VolumeShrimp = "";
        this.VolumeShrimpPoop = "";
        this.Organ = "";
        this.HelthShrimp = "";
    }
}

export class FormDailyReportModel {
    constructor(){
        this.date = undefined;
        this.pond = undefined;
        // Fooding
        this.Fooding = [new FormFoodingModel()];
        // Check yor
        this.CheckYor = [new FormCheckYorModel()];
        // Water Quality
        this.waterQuality = {
            OxygenMorning : undefined,
            OxygenAfternon : undefined,
            PhMorning : undefined,
            PhAfternoon : undefined,
            TempMorning : undefined,
            TempAfternoon : undefined,
            Bicarbonate : undefined,
            Carbonate : undefined,
            Amonia : undefined,
            Nitrite : undefined,
            Salinity : undefined,
            Hardness : undefined,
        };
        // Other
        this.other = {
            CalciumChloride : undefined,
            MagniciumChloride : undefined,
            DissolvedOrganicCarbon : undefined,
        };
        // Summary
        this.summary = "";
    }
    mapAttribute(inputObject) {
        Object.keys(inputObject).forEach((element) => {
          this[element] = inputObject[element];
        });
    }
}