export const ACCESS_DAILY_REPORT = {
    I: "access",
    a: "apps.farm_module.daily_report.access",
};

export const VIEW_FOOD = {
    I: "get",
    a: "apps.farm_module.daily_report.daily_report.view_food",
};
export const UPDATE_FOOD = {
    I: "put",
    a: "apps.farm_module.daily_report.daily_report.update_food",
};

export const VIEW_YOR = {
    I: "get",
    a: "apps.farm_module.daily_report.daily_report.view_yor",
};
export const UPDATE_YOR = {
    I: "put",
    a: "apps.farm_module.daily_report.daily_report.update_yor",
};

export const VIEW_WATER = {
    I: "get",
    a: "apps.farm_module.daily_report.daily_report.view_water",
};
export const UPDATE_WATER = {
    I: "put",
    a: "apps.farm_module.daily_report.daily_report.update_water",
};

export const VIEW_OTHER = {
    I: "get",
    a: "apps.farm_module.daily_report.daily_report.view_other",
};
export const UPDATE_OTHER = {
    I: "put",
    a: "apps.farm_module.daily_report.daily_report.update_other",
};

export const VIEW_SAMPLING = {
    I: "get",
    a: "apps.farm_module.daily_report.animal_report.view_sampling",
};
export const UPDATE_SAMPLING = {
    I: "put",
    a: "apps.farm_module.daily_report.animal_report.update_sampling",
};

export const VIEW_BODYSHRIMP = {
    I: "get",
    a: "apps.farm_module.daily_report.animal_report.view_body_shrimp",
};
export const UPDATE_BODYSHRIMP = {
    I: "put",
    a: "apps.farm_module.daily_report.animal_report.update_body_shrimp",
};

export const VIEW_LIVERSHRIMP = {
    I: "get",
    a: "apps.farm_module.daily_report.animal_report.view_liver_shrimp",
};
export const UPDATE_LIVERSHRIMP = {
    I: "put",
    a: "apps.farm_module.daily_report.animal_report.update_liver_shrimp",
};

export const VIEW_DETAIL = {
    I: "get",
    a: "apps.farm_module.daily_report.water_report.view_detail",
};
export const UPDATE_DETAIL = {
    I: "put",
    a: "apps.farm_module.daily_report.water_report.update_detail",
};

export const VIEW_BACTERIA = {
    I: "get",
    a: "apps.farm_module.daily_report.water_report.view_bacteria",
};
export const UPDATE_BASTERIA = {
    I: "put",
    a: "apps.farm_module.daily_report.water_report.update_bacteria",
};
