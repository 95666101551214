// Farm System
export const SET_INFO_FARM_SYSTEM = "FARM_MODULE/FARM_SYSTEM/SET_INFO";
export const SET_MORE_DETAIL_FARM_SYSTEM = "FARM_SYSTEM/SET_MORE_DETAIL";
export const SET_FARMS_FARM_SYSTEM = "FARM_SYSTEM/SET_FARMS";

// Farm
export const SET_FARM_LIST = "FARM_SYSTEM/FARM/FARM_LIST";
export const SET_FARM_INFO = "FARM_SYSTEM/FARM/SET_FARM_INFO";

// Pond
export const SET_POND_LIST = "FARM_SYSTEM/POND/POND_LIST";
export const SET_POND_STATUS = "FARM_SYSTEM/POND/POND_STATUS";
