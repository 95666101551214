import { lazy } from "react";

const UsersListPage = lazy(() => import("./user-list"));
const ProfilePage = lazy(() => import("./ProfilePage"));

const RouteUsers = [
    {
        index: true,
        path: "",
        element: <UsersListPage />,
    },
    {
        index: true,
        path: ":id",
        element: <ProfilePage />,
    },
];

export default RouteUsers;
