export const PENDING_ORDER_APPROVAL = "pending order approval";
export const ORDER_CANCELLATION = "order cancellation";
export const ORDER_APPROVAL = "order approval";
export const ORDER_REJECTION = "order rejection";
export const ORDER_ARRIVED = "order arrived";
export const HEALTH_CHECK = "health check";
export const PENDING_REPORT_APPROVAL = "pending report approval";
export const REPORT_APPROVAL = "report approval";
export const REPORT_REJECTION = "report rejection";
export const AWAITING_PAYMENT = "awaiting payment";
export const FINSIHED = "finished";
export const RAISED_SHRIMP = "raised shrimp";

export const AFPD_STATUS = {};

AFPD_STATUS[PENDING_ORDER_APPROVAL] = {
    color: "blue",
    label: "รออนุมัติแผนการสั่งซื้อ",
};
AFPD_STATUS[ORDER_CANCELLATION] = {
    color: "red",
    label: "ยกเลิกแผนการสั่งซื้อ",
};
AFPD_STATUS[ORDER_APPROVAL] = {
    color: "green",
    label: "อนุมัติแผนการสั่งซื้อ",
};
AFPD_STATUS[ORDER_REJECTION] = {
    color: "red",
    label: "ไม่อนุมัติแผนการสั่งซื้อ",
};
AFPD_STATUS[ORDER_ARRIVED] = { color: "blue", label: "ลูกกุ้งมาถึงเรียบร้อย" };
AFPD_STATUS[HEALTH_CHECK] = { color: "gray", label: "ตรวจสอบสภาพลูกกุ้ง" };
AFPD_STATUS[PENDING_REPORT_APPROVAL] = {
    color: "blue",
    label: "รออนุมัติการลงลูกกุ้ง",
};
AFPD_STATUS[REPORT_APPROVAL] = { color: "green", label: "อนุมัติการลงลูกกุ้ง" };
AFPD_STATUS[REPORT_REJECTION] = { color: "red", label: "ยกเลิกการลงลูกกุ้ง" };
AFPD_STATUS[AWAITING_PAYMENT] = { color: "blue", label: "รอการชำระเงิน" };
AFPD_STATUS[FINSIHED] = { color: "green", label: "ชำระเงินเรียบร้อย" };

AFPD_STATUS[RAISED_SHRIMP] = { color: "green", label: "ลงลูกกุ้งเรียบร้อย" };
