import { lazy } from "react";
import { APP_PREFIX_PATH, DASHBOARD_MODULE_PATH } from "configs/AppConfig";
import { RouteDashboard } from "./Dashboard";

const CustomDashboard = lazy(async () => {
    return import("./Dashboard-custom");
});

const AdjustDashboard = lazy(async () => {
    return import("./Dashboard-custom/Adjust");
});


export const RouteDashboardModule = {
    path: `${APP_PREFIX_PATH}/${DASHBOARD_MODULE_PATH}`,
    children: [
        {
            path: "general",
            children: RouteDashboard,
        },
        {
            path: "custom",
            children: [
                {
                    path: ":ProfileID",
                    children: [
                        {
                            index: true,
                            path: "",
                            element: <CustomDashboard />,
                        },
                        {
                            path: "adjust",
                            element: <AdjustDashboard />,
                        },
                    ],
                },
            ],
        },
    ],
};
