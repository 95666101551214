import { lazy } from "react";

const PondDetailPage = lazy(() => import("./PondTab"));
const DailyReportPage = lazy(() => import("./DailyReport"));
const SettingPage = lazy(() => import("./Setting"));
const LabShrimpPage = lazy(() => import("./LabShrimp"));
const LabWaterPage = lazy(() => import("./LabWater"));

export const RoutePond = [
    {
        index: true,
        path: "",
        element: <PondDetailPage />,
    },

    // Daily Report
    {
        index: true,
        path: `daily-report`,
        element: <DailyReportPage />,
    },
    {
        index: true,
        path: `lab-shrimp`,
        element: <LabShrimpPage />,
    },
    {
        index: true,
        path: `lab-water`,
        element: <LabWaterPage />,
    },

    {
        index: true,
        path: `setting`,
        element: <SettingPage />,
    },
];
