import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { alertChange, alertLoading } from "redux/actions/alert.actions";
import { css } from "@emotion/css";

function AlertPopup(props) {
    const { alert } = props;

    let alertLoading = false;

    const [offsetY, setOffsetY] = useState(0);
    const backToTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    useEffect(() => {
        setOffsetY(window.pageYOffset);
        const handleScroll = () => {
            setOffsetY(window.pageYOffset);
        };
        window.addEventListener("scroll", handleScroll);
        return function cleanup() {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    /* eslint-enable */

    return (
        <>
            <div
                className={`global-loader ${
                    alert.loading ? "active" : ""
                } ${css`
                    z-index: ${alert.loading ? 10000 : -1};
                `}`}>
                <div className="wrapper">
                    <div className="spinner-border text-primary">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>

            <button
                className={`back-to-top ${
                    offsetY > 50 ? "active" : ""
                } btn btn-primary btn-icon scroll-top waves-effect waves-float waves-light`}
                type="button"
                onClick={backToTop}>
                <em className="fa fa-long-arrow-up"></em>
            </button>
        </>
    );
}

AlertPopup.defaultProps = {};
AlertPopup.propTypes = {
    alertChange: PropTypes.func.isRequired,
    alertLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    alert: state.alert,
});

export default connect(mapStateToProps, {
    alertChange: alertChange,
    alertLoading: alertLoading,
})(AlertPopup);
