import { lazy } from "react";

const WarehouseListPage = lazy(async () => {
    return import("./WarehouseList");
});

const NewProductPage = lazy(async () => {
    return import("../Inventory/NewProduct");
});

const SubWarehouseInfo = lazy(async () => {
    return import("./SubWarehouse");
});

const AddStockPage = lazy(async () => {
    return import("./SubWarehouse/AddStock");
});

const RequisitionPage = lazy(async () => {
    return import("./SubWarehouse/Requisition");
});

const SettingPage = lazy(async () => {
    return import("./Setting");
});

const RouteWarehouses = [
    {
        index: true,
        path: "",
        element: <WarehouseListPage />,
    },
    {
        path: ":warehouseID",
        children: [
            {
                index: true,
                path: "",
                element: <SubWarehouseInfo />,
            },
            {
                index: true,
                path: "add-stock",
                element: <AddStockPage />,
            },
            {
                index: true,
                path: "requisition",
                element: <RequisitionPage />,
            },
            {
                index: true,
                path: "setting",
                element: <SettingPage />,
            },
            {
                index: true,
                path: "new",
                element: <NewProductPage />,
            },
        ],
    },
];
export default RouteWarehouses;
