/* ............Step 2............ */
export class FormCreateFarmStep2Model {
  // Farm's licenses
  constructor() {
    this.farm_haveLicenses = true;
    this.farm_licenses = [new FarmLicenseModel()];
  }
}

export class FarmLicenseModel {
  // Farm's licenses
  constructor() {
    this.farm_licenseType = "";
    this.farm_licenseNumber = "";
    this.farm_licenseExpirationDate = "";
    this.farm_licenseImages = [];
  }
}
