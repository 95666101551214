export default class PaginateModel {
    constructor(data) {
        this.hasNextPage = data?.hasNextPage;
        this.hasPrevPage = data?.hasPrevPage;
        this.limit = data?.limit;
        this.nextPage = data?.nextPage;
        this.offset = data?.offset;
        this.page = data?.page || 1;
        this.pagingCounter = data?.pagingCounter;
        this.prevPage = data?.prevPage;
        this.totalDocs = data?.totalDocs;
        this.totalPages = data?.totalPages || 1;
    }

    ishasNextPage = () => this.hasNextPage;
    ishasPrevPage = () => this.hasPrevPage;
    displayLimit = () => this.limit;
    displayNextPage = () => this.nextPage;
    displayOffset = () => this.offset;
    displayPage = () => this.page;
    displayPagingCounter = () => this.pagingCounter;
    displayPrevPage = () => this.prevPage;
    displayTotalData = () => this.totalDocs;
    displayTotalPages = () => this.totalPages;
}
