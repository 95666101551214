export default class TodolistModel {
    constructor(data) {
        // this.id = data?.id || '';
        this.category = data?.category || "SoidPreparation";
        this.topic = data?.topic || "";
        this.description = data?.description || "";
        this.startDate = data?.startDate || "";
        this.endDate = data?.endDate || "";
        this.repeat = data?.repeat || [];
        // this.isRepeat = data?.isRepeat || false;
        this.isDone = data?.isDone || false;
        this.assigner = data?.assigner || '';
        // this.assignee = data?.assignee || '';
        // this.WaterVolume = data?.WaterVolume || 0;
        // this.instruction = data?.instruction || '';
        // this.component = data?.component || [];
    }
}
