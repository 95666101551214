export const BROKE = 'item broke'
export const BEEN_FIXING = 'item are fixing'
export const FIXED = 'item have fixed'
export const CANCEL = 'cancelled item'

export const REPAIR_STATUS = {};

REPAIR_STATUS[BROKE] = {    
    color: "red", 
    colorB1: "danger", 
    colorB2: "warning", 
    label: "สินค้าชำรุด", 
    label2: "ซ่อมสินค้า", };

REPAIR_STATUS[BEEN_FIXING] = { 
    color: "orange", 
    colorB1: "danger", 
    colorB2: "success", 
    label: "กำลังซ่อม", 
    label2: "คิดราคา",};

REPAIR_STATUS[FIXED] = { 
    color: "green", 
    colorB1: "secondary", 
    colorB2: "secondary", 
    label: "ซ่อมเสร็จแล้ว", 
    label2: "ซ่อมแล้ว",};

REPAIR_STATUS[CANCEL] = { 
    color: "light-secondary", 
    colorB1: "secondary", 
    colorB2: "secondary", 
    label: "ยกเลิกแล้ว", 
    label2: "ยกเลิก",};