/* ............Step 5............ */
// Pond connection
export class PondConnectionModel {
    constructor(pondName, pondType, pondId = "", connectWith = []) {
        this.pondConnection_pondName = pondName;
        this.pondConnection_pondType = pondType;
        this.pondConnection_IsOpen = true;
        this.pondConnection_pondId = pondId;
        this.connectWith = connectWith;
    }
}
