const TransformerIcon = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt"
            height="512.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none">
                <path
                    d="M1558 5106 c-27 -20 -38 -62 -38 -147 l0 -79 -55 0 c-42 0 -60 -5
-75 -20 -33 -33 -182 -340 -183 -378 -1 -41 30 -72 74 -72 16 0 29 -2 29 -5 0
-3 -23 -51 -51 -107 -58 -116 -65 -161 -30 -189 13 -11 36 -19 52 -19 16 0 29
-2 29 -5 0 -3 -23 -51 -51 -107 -29 -59 -52 -116 -52 -136 -1 -49 30 -72 99
-72 l54 0 0 -84 0 -85 -302 -3 c-347 -3 -353 -5 -395 -88 -20 -37 -23 -60 -23
-147 0 -66 5 -116 14 -137 20 -47 65 -85 109 -92 l37 -7 0 -1247 0 -1248 -70
-4 c-74 -4 -105 -19 -144 -72 -20 -26 -21 -41 -21 -241 0 -235 1 -239 69 -289
l27 -21 1899 0 1899 0 27 21 c68 50 69 54 69 289 0 200 -1 215 -21 241 -39 53
-70 68 -144 72 l-70 4 0 1248 0 1247 38 7 c43 7 88 45 108 92 9 21 14 71 14
137 0 87 -3 110 -22 147 -43 83 -49 85 -395 88 l-303 3 0 85 0 84 54 0 c69 0
100 23 99 72 0 20 -23 77 -52 136 -28 56 -51 104 -51 107 0 3 13 5 29 5 16 0
39 8 52 19 35 28 28 73 -30 189 -28 56 -51 104 -51 107 0 3 13 5 29 5 44 0 75
31 74 72 -1 38 -150 345 -183 378 -15 15 -33 20 -75 20 l-55 0 0 79 c0 44 -5
92 -10 107 -21 55 -68 69 -114 33 -25 -20 -26 -24 -26 -120 l0 -99 -53 0 c-32
0 -61 -6 -75 -16 -30 -21 -192 -345 -192 -383 0 -36 40 -71 81 -71 l29 0 -55
-110 c-30 -60 -55 -123 -55 -139 0 -36 40 -71 81 -71 l29 0 -55 -110 c-30 -60
-55 -122 -55 -138 0 -44 35 -70 100 -74 l55 -3 3 -82 3 -83 -246 0 -245 0 0
85 0 85 55 0 c65 0 95 22 95 70 0 17 -25 80 -55 140 l-55 110 33 0 c43 0 77
31 77 71 0 16 -25 79 -55 139 l-55 110 29 0 c41 0 81 35 81 71 0 16 -37 104
-87 203 -94 189 -101 196 -178 196 l-45 0 0 73 c0 101 -13 142 -50 157 -24 10
-36 10 -60 0 -37 -15 -50 -56 -50 -157 l0 -73 -45 0 c-77 0 -84 -7 -178 -196
-50 -99 -87 -187 -87 -203 0 -36 40 -71 81 -71 l29 0 -55 -110 c-30 -60 -55
-123 -55 -139 0 -40 34 -71 77 -71 l33 0 -55 -110 c-30 -60 -55 -123 -55 -140
0 -48 30 -70 95 -70 l55 0 0 -85 0 -85 -245 0 -246 0 3 83 3 82 55 3 c65 4
100 30 100 74 0 16 -25 78 -55 138 l-55 110 29 0 c41 0 81 35 81 71 0 16 -25
79 -55 139 l-55 110 29 0 c41 0 81 35 81 71 0 38 -162 362 -192 383 -14 10
-43 16 -75 16 l-53 0 0 99 c0 96 -1 100 -26 120 -30 24 -61 26 -86 7z m192
-456 c22 -44 40 -82 40 -85 0 -3 -88 -5 -195 -5 l-195 0 42 85 42 85 113 0
113 0 40 -80z m968 -5 l42 -85 -200 0 -200 0 42 85 42 85 116 0 116 0 42 -85z
m960 0 l42 -85 -195 0 c-107 0 -195 2 -195 5 0 3 18 41 40 85 l40 80 113 0
113 0 42 -85z m-1928 -315 c22 -44 40 -82 40 -85 0 -3 -88 -5 -195 -5 l-195 0
42 85 42 85 113 0 113 0 40 -80z m968 -5 l42 -85 -200 0 -200 0 42 85 42 85
116 0 116 0 42 -85z m960 0 l42 -85 -195 0 c-107 0 -195 2 -195 5 0 3 18 41
40 85 l40 80 113 0 113 0 42 -85z m-1928 -315 c22 -44 40 -82 40 -85 0 -3 -88
-5 -195 -5 l-195 0 42 85 42 85 113 0 113 0 40 -80z m968 -7 l43 -83 -201 0
-200 0 36 73 c20 39 41 78 47 85 7 9 40 12 121 10 l111 -3 43 -82z m960 2 l42
-85 -195 0 c-107 0 -195 2 -195 5 0 3 18 41 40 85 l40 80 113 0 113 0 42 -85z
m-1998 -320 l0 -85 -85 0 -85 0 0 85 0 85 85 0 85 0 0 -85z m968 -2 l3 -83
-91 0 -90 0 0 78 c0 43 3 82 7 86 4 4 43 5 88 4 l80 -3 3 -82z m962 2 l0 -85
-85 0 -85 0 0 85 0 85 85 0 85 0 0 -85z m718 -322 l3 -83 -119 0 c-126 0 -176
-11 -192 -40 -16 -30 -12 -64 10 -88 17 -18 32 -22 80 -22 l60 0 0 -1250 0
-1250 -1414 0 c-778 0 -1422 -3 -1431 -6 -8 -4 -23 -20 -31 -36 -19 -37 -5
-80 32 -97 18 -8 439 -11 1554 -11 l1530 0 0 -165 0 -165 -1850 0 -1850 0 0
165 0 165 175 0 c173 0 176 0 200 25 59 58 15 125 -81 125 l-54 0 0 1250 0
1250 284 0 284 0 4 -60 c5 -71 26 -100 73 -100 53 0 69 20 75 92 l5 63 403 3
402 2 1 -37 c0 -61 20 -106 52 -117 62 -22 105 25 106 117 l1 37 403 -2 402
-3 5 -63 c6 -72 22 -92 75 -92 47 0 68 29 73 100 l4 60 98 0 c84 0 99 3 115
20 25 28 23 76 -4 103 l-22 22 -1499 3 -1500 2 0 78 c0 43 3 82 7 85 3 4 799
6 1767 5 l1761 -3 3 -82z"
                />
                <path
                    d="M2516 2635 c-22 -8 -47 -22 -57 -32 -10 -10 -192 -319 -405 -688
-343 -595 -387 -676 -391 -720 -5 -57 17 -101 64 -132 26 -17 73 -18 833 -18
760 0 807 1 833 18 47 31 69 75 64 132 -4 44 -48 125 -392 720 -213 369 -392
676 -398 683 -31 38 -100 55 -151 37z m420 -797 l372 -643 -374 -3 c-206 -1
-542 -1 -748 0 l-374 3 372 643 c204 353 373 642 376 642 3 0 172 -289 376
-642z"
                />
                <path
                    d="M2516 2088 c-18 -17 -38 -66 -75 -184 -28 -89 -51 -175 -51 -193 0
-45 29 -65 104 -71 l63 -5 -33 -105 c-19 -58 -34 -120 -34 -137 0 -77 99 -102
134 -34 22 43 106 327 106 358 0 46 -31 66 -104 71 l-63 4 33 107 c42 131 43
163 8 190 -34 27 -57 26 -88 -1z"
                />
            </g>
        </svg>
    );
};

export default TransformerIcon;
