export class ShrimpWeightModel {
    constructor(){
        this.weight = 0;
    }
}

export class LabReportModel {
    constructor(){
        // Lab Shrimp
        this.ShrimpWeight = [new ShrimpWeightModel()];
        this.AVGweight = 0;
        // Shrimp Anomaly
        this.Fat = 0;
        this.LeftLiverCell = 0;
        this.ConstrictedLiver = 0;
        this.YellowLiverTube = 0;
        this.SwellLiver = 0;
        this.DamagedLiver = 0;
        this.Krasuay = 0;
        this.GroupFat = 0;
        this.Microsporidia = 0;
        this.ZooPlanktonBody = 0;
        this.ZooPlanktonLeg = 0;
        this.ZooPlanktonTail = 0;
        this.MucusIntestine = 0;
        this.SwellIntestine = 0;
        this.dregs = 0;
        this.Anomaly = "";
        // Shrimp Liver Anomally
        this.Purple_Liver = 0;
        this.White_Liver = 0;
        this.Green_Liver = 0;
        this.Total_Liver = 0;
        this.LiverAnomally_Image = [];

        // Lab Water
        this.Collect_Time = "";
        this.Collect_Place = "";
        this.Collect_Method = "";
        this.Collect_Volume = 0;
        this.Collect_Deep = 0;
        // Water Anomally
        this.Yellow_Water = 0;
        this.Green_Water = 0;
        this.Black_Water = 0;
        this.Total_Water = 0 ;
        this.WaterAnomally_Image = [];
    }
    
    findAverageAge = () => {
        const { length } = this.ShrimpWeight;
        const intd = 0;
        const exp = this.ShrimpWeight[0].weight;
        return intd
    };

    findSum = () =>{
        const sum = this.Purple_Liver + this.White_Liver +  this.Green_Liver;
        console.log(this.Purple_Liver)
        return sum
    };
}