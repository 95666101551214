import { APP_PREFIX_PATH, FARM_MODULE_PATH } from "configs/AppConfig";
import { ACTIVE, FARMING, PREPARING } from "constants/Status/FarmModule/Pond";
import { alertToast } from "redux/actions/alert.actions";
import {
    SET_POND_LIST,
    SET_POND_STATUS,
} from "redux/constants/farmModule.constants";
import FarmServices from "services/FarmServices";
import PondService from "services/PondService";

export const setPondStatus = (payload) => async (dispatch) =>
    dispatch({
        type: SET_POND_STATUS,
        payload: payload,
    });

export const getPondList = (payload) => async (dispatch, state) => {
    const { status, data } = await FarmServices.getPondList({
        query: {
            module: "Pond",
            farmId: payload,
        },
    });
    if (status === 200) {
        let ponds = [];
        data.forEach((pondType) => {
            const pondList = pondType.pondList.map((pond, index) => {
                return {
                    id: pond._id,
                    name: pond.pondName,
                    type: pondType.pondTypeName,
                    typeId: pondType._id,
                    status: pond.status,
                    link: `${APP_PREFIX_PATH}/${FARM_MODULE_PATH}/farms/${payload.path.farmId}/ponds/${pond._id}`,
                };
            });

            ponds = [...ponds, ...pondList];
        });

        dispatch({
            type: SET_POND_LIST,
            payload: ponds,
        });
        return data;
    } else {
        dispatch(
            alertToast("error", {
                message: "โหลดข้อมูลไม่สำเร็จ",
            })
        );
        dispatch({
            type: SET_POND_LIST,
            payload: [],
        });
        return false;
    }
};

export const getPondsListOptions = (payload) => async (dispatch, state) => {
    const { status, data } = await FarmServices.getPondList(payload);

    if (status !== 200) {
        return [];
    }
    return data
        .map((pondType) => {
            return pondType.pondList.map((pond) => {
                return { value: pond._id, label: pond.pondName };
            });
        })
        .flat();
};

export const getPondTypesListOptions = (payload) => async (dispatch, state) => {
    const { status, data } = await FarmServices.getPondTypeList(payload);

    if (status !== 200) {
        return [];
    }
    return data
        .map((pondType) => {
            return { value: pondType._id, label: pondType.pondTypeName };
        })
        .flat();
};

export const getPondDetail =
    (farmId, pondListId, pondId) => async (dispatch, state) => {
        const { status, data } = await FarmServices.getPondDetail({
            path: { farmId: farmId, pondListId: pondListId, pondId: pondId },
        });
        if (status !== 200) {
            dispatch(
                alertToast("error", {
                    message: "โหลดข้อมูลไม่สำเร็จ",
                })
            );
            return false;
        }
        return data;
    };

export const editPondConnection =
    (farmId, pondListId, pondId, connectWith) => async (dispatch, state) => {
        const { status, data } = await FarmServices.serviceEditConnectPond({
            path: { farmId, pondListId, pondId },
            data: { connectWith },
        });
        if (status !== 200) {
            dispatch(
                alertToast("error", {
                    message: "โหลดข้อมูลไม่สำเร็จ",
                })
            );
            return false;
        }
        return data;
    };

export const deletePondAction =
    (farmId, pondListId, pondId) => async (dispatch) => {
        const { status, data } = await FarmServices.deletePond({
            path: { farmId, pondListId, pondId },
        });
        dispatch(
            getPondList({
                path: {
                    farmId,
                },
            })
        );
        if (status !== 200) {
            dispatch(
                alertToast("error", {
                    message: "ลบบ่อไม่สำเร็จ",
                })
            );
            return false;
        }
        return data;
    };

export const startFarmingAction = (farmId, pondId) => async (dispatch) => {
    const { status, data } = await PondService.serviceStartFarming({
        path: { farmId, pondId },
    });
    if (status !== 200) {
        dispatch(
            alertToast("error", {
                message: "ไม่สำเร็จ",
            })
        );
        return false;
    }
    const reducerPayload = { id: pondId, status: FARMING };
    dispatch(setPondStatus(reducerPayload));
    return data;
};

export const startPreparingAction = (farmId, pondId) => async (dispatch) => {
    const { status, data } = await PondService.serviceStartPreparing({
        path: { farmId, pondId },
    });
    if (status !== 200) {
        dispatch(
            alertToast("error", {
                message: "ไม่สำเร็จ",
            })
        );
        return false;
    }
    const reducerPayload = { id: pondId, status: PREPARING };
    dispatch(setPondStatus(reducerPayload));
    return data;
};

export const cancelPreparingAction = (farmId, pondId) => async (dispatch) => {
    const { status, data } = await PondService.serviceCancelPreparing({
        path: { farmId, pondId },
    });
    if (status !== 200) {
        dispatch(
            alertToast("error", {
                message: "ไม่สำเร็จ",
            })
        );
        return false;
    }
    const reducerPayload = { id: pondId, status: ACTIVE };
    dispatch(setPondStatus(reducerPayload));
    return data;
};
