import React from "react";
import styled from "styled-components";
import { ArrowRight } from 'react-feather';

const GoNextIconStyled = styled(ArrowRight)`
    &:hover {
        cursor: pointer;
    }
`;

const GoNextIcon = ({ ...buttonProperties }) => (
    <GoNextIconStyled  {...buttonProperties}/>
);

export default GoNextIcon;