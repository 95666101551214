import { 
    CLIENT_IP_UPDATE, 
    SIDENAV_ACTIVE_INDEX, 
    THEME_UPDATE,
    WINDOW_WIDTH,
} from 'redux/constants/general.constants';


// Security
export const clientIpUpdate = () => async (dispatch) => {
    const fetch1 = await fetch('https://geolocation-db.com/json/');
    const data1 = await fetch1.json();
    dispatch({ 
        type: CLIENT_IP_UPDATE, 
        payload: data1.IPv4 
    });
    return data1.IPv4;
};

// Sidenav
export const setSidenavActiveIndex = (index) => (dispatch) => {
    dispatch({
        type: SIDENAV_ACTIVE_INDEX,
        payload: index
    });
    return true;
};

// Theme
export const themeUpdate = (value) => (dispatch) => {
    dispatch({
        type: THEME_UPDATE,
        payload: value
    });
    return true;
};

export const updateWindowWidth = (width) => (dispatch) => dispatch({type: WINDOW_WIDTH, payload: width})
