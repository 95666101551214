import { lazy } from "react";

const SystemDashboard  = lazy(async () =>{
    return import('./SystemDashboard')
})

const GroupFarmDashboard = lazy(async () =>{
    return import('./GroupFarm')
})

const FarmDashboard = lazy(async () =>{
    return import("./Farm")
});

const ZoneDashboard = lazy(async () =>{
    return import("./Zone")
});

const PondDashboard = lazy(async () =>{
    return import("./Pond")
});

export const RouteDashboard = [
    {
        index: true,
        path: "",
        element: <SystemDashboard />,
    },
    {
        path: ":GroupFarmID",
        children: [
            {
                index: true,
                path: "",
                element: <GroupFarmDashboard />,
            },
            {
                path: ":FarmID",
                children: [
                    {
                        index: true,
                        path: "",
                        element: <FarmDashboard />,
                    },
                    {
                        path: ":ZoneID",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ZoneDashboard />,
                            },
                            {
                                path: ":PondID",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <PondDashboard />,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
    