const FullMoonIcon = () => {
    return (
        <>
            <svg
                width="38"
                height="42"
                viewBox="0 0 38 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M36.4688 32.625C36.375 32.625 36.375 32.625 36.2812 32.7188C35.25 32.9062 34.2188 33 33.1875 33C24.2812 33 16.875 25.7812 16.875 16.5C16.875 10.5938 19.9688 5.15625 25.125 2.25C25.9688 1.78125 25.7812 0.5625 24.8438 0.375C23.8125 0.1875 22.125 0 21 0C9.375 0 0 9.46875 0 21C0 32.625 9.375 42 20.9062 42C27.375 42 33.2812 39.0938 37.2188 34.3125C37.7812 33.5625 37.2188 32.625 36.4688 32.625ZM20.9062 37.5C11.8125 37.5 4.5 30.0938 4.5 21C4.5 13.875 9 7.78125 15.375 5.53125C13.4062 8.71875 12.2812 12.5625 12.2812 16.5C12.2812 25.875 18.375 33.75 26.8125 36.4688C24.9375 37.2188 22.9688 37.5 20.9062 37.5Z"
                    fill="#343536"
                />
            </svg>
        </>
    );
};

export default FullMoonIcon;
