import { APP_PREFIX_PATH, ATTEND_MODULE_PATH } from "configs/AppConfig";
// ** Icons Import
import { Calendar, Menu, DollarSign } from "react-feather";

const PREFIX = `${APP_PREFIX_PATH}/${ATTEND_MODULE_PATH}`;

/* eslint-disable */
export default [
    {
        header: "การทำงาน",
    },
    {
        id: "Employee",
        title: "เข้างาน",
        icon: <Calendar />,
        navLink: `${PREFIX}/employee`,
    },
    {
        id: "EmpManagement",
        title: "จัดการสมาชิก",
        icon: <Menu size={50} />,
        navLink: `${PREFIX}/empmanage`,
    },
    {
        id: "SalaryManagement",
        title: "ค้าจ้าง",
        icon: <DollarSign size={50} />,
        navLink: `${PREFIX}/salary`,
    },
];
