
export const EXPIRED = 'license expired';
export const IN_PROGRESS_GREEN = 'license can use more than 90 day';
export const IN_PROGRESS_YELLOW = 'license will expire in 30-90 day';
export const IN_PROGRESS_ORANGE = 'license will expire in 7-30 day';
export const IN_PROGRESS_RED = 'license will expire in 1-7 day';
export const DISABLED = 'license was disabled';

export const LICENSE_STATUS = {};

LICENSE_STATUS[EXPIRED] = {
    color: "light-secondary",
    label: "หมดอายุ",
};

LICENSE_STATUS[IN_PROGRESS_GREEN] = {
    color: "green",
    label: "กำลังใช้งาน",
};

LICENSE_STATUS[IN_PROGRESS_YELLOW] = {
    color: "yellow",
    label: "กำลังใช้งาน",
};

LICENSE_STATUS[IN_PROGRESS_ORANGE] = {
    color: "orange",
    label: "กำลังใช้งาน",
};

LICENSE_STATUS[IN_PROGRESS_RED] = {
    color: "red",
    label: "กำลังใช้งาน",
};

LICENSE_STATUS[DISABLED] = {
    color: "light-secondary",
    label: "หยุดใช้งาน",
};