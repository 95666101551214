import { PaginateModel } from "models";

import {
    PAGINATE_SET,
    PAGINATE_CLEAR,
} from "redux/constants/paginate.constants";

const initialState = new PaginateModel({});
var temp;

const paginateReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAGINATE_SET:
            temp = new PaginateModel(action.payload);
            return { ...state, ...temp };

        case PAGINATE_CLEAR:
            temp = new PaginateModel({});
            return temp;

        default:
            return state;
    }
};

export default paginateReducer;
