import { BROKE, BEEN_FIXING, FIXED, CANCEL } from "constants/Status/RepairModule";
import { CHANGE_REPAIR_STATUS, ON_CHANGE_FORM } from "redux/constants/repair.constant";

const mockData = [
    {
        id: 1,
        lebel: 'มอเตอร์ 1',
        itemid: '12345',
        status: BROKE,
        charge: '1000',
    },
    {
        id: 2,
        lebel: 'มอเตอร์ 2',
        itemid: '32141',
        status: BROKE,
        charge: '1000',
    },
    {
        id: 3,
        lebel: 'มอเตอร์ 3',
        itemid: '41276',
        status: BEEN_FIXING,
        charge: '1000',
    },
    {
        id: 4,
        lebel: 'มอเตอร์ 4',
        itemid: '12345',
        status: BEEN_FIXING,
        charge: '1000',
    },
    {
        id: 5,
        lebel: 'มอเตอร์ 5',
        itemid: '32141',
        status: FIXED,
        charge: '1000',
    },
    {
        id: 6,
        lebel: 'มอเตอร์ 6',
        itemid: '41276',
        status: CANCEL,
        charge: '1000',
    },
]

const initialState = {
    brokeitemList: mockData
};

const RepairModuleReducer = (state = initialState, action) => {
    const {payload } = action;
    let changed = state;

    switch (action.type){
        case CHANGE_REPAIR_STATUS :{
            const { id, status } = payload;

            let newList = state.brokeitemList;
            for (var index in newList) {
                if (newList[index].id === id) {
                    newList[index].status = status;
                    break;
                }
            }
            changed = {
                ...state,
                brokeitemList: newList,
            };
        }

        case ON_CHANGE_FORM :{
            const { id, value } = payload;

            let newList = state.brokeitemList;
            for (var index in newList) {
                if (newList[index].id === id) {
                    newList[index].charge = value;
                    break;
                }
            }
            changed = {
                ...state,
                brokeitemList: newList,
            };
        }
    }
    
    return changed;
};

export default RepairModuleReducer;