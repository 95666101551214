/* ............Step 3............ */
export class FormCreateFarmStep3Model {
  // Farm's licenses
  constructor() {
    // Pump station
    this.pumpStationList = [new PumpStationModel()];
    // Pump period
    this.havePumpPeriod = true;
    this.pumpPeriodUpMoon = [new PumpPeriodModel()];
    this.pumpPeriodDownMoon = [new PumpPeriodModel()];
    // Pump motor
    this.pumpMotorList = [new PumpMotorModel()];
    // Electric system
    this.haveEletricSystem = true;
    this.transformer = [new TransformerModel()];
    // Generator
    this.haveGenerator = true;
    this.generator = [new GeneratorModel()];
  }
}

export class PumpStationModel {
  // Single pump station
  constructor() {
    this.pumpStation_motors = [new MotorModel()];
  }
}
export class PumpMotorModel {
  // Single pump station
  constructor() {
    this.pumpMotor_motors = [new MotorModel()];
  }
}
export class MotorModel {
  // Single motor
  constructor() {
    this.motor_brand = "";
    this.motor_size = 0;
    this.motor_pipeSize = 0;
  }
}
export class PumpPeriodModel {
  // Both up moon & down moon
  constructor() {
    this.lunaDay = "";
    this.start = "";
    this.end = "";
  }
}
export class GeneratorModel {
  constructor() {
    this.power = "";
    this.consumptionRate = "";
  }
}
export class TransformerModel {
  constructor() {
    this.power = "";
    this.electricSystem = "";
    this.type = "";
  }
}
