import { lazy } from "react";
import { APP_PREFIX_PATH, LICENSE_MODULE_PATH } from "configs/AppConfig";

const LicensePage = lazy(async () => {
    return import("./LicenseList");
});
const CreateLicense = lazy(async () => {
    return import("./AddLicense");
});
const SettingLicense = lazy(async () => {
    return import("./Setting");
});

export const RouteLicenseModule = {
    path: `${APP_PREFIX_PATH}/${LICENSE_MODULE_PATH}`,
    children: [
        {
            index: true,
            path: "List",
            element: <LicensePage />,
        },
        {
            index: true,
            path: "newlicense",
            element: <CreateLicense />,
        },
        {
            index: true,
            path: "setting",
            element: <SettingLicense />,
        },
    ],
};
