import { CHECK_READ } from "redux/constants/notification";

const mockData = [
    {
        id: 1,
        link: "/todo",
        title: "แจ้งเตือนสต็อกสินค้าใกล้หมด",
        since: "2 ชั่วโมงที่แล้ว",
        status: "NOT READ",
    },
    {
        id: 2,
        link: "/todo",
        title: "แจ้งเตือนสต็อกสินค้าใกล้หมด",
        since: "2 ชั่วโมงที่แล้ว",
        status: "READ",
    },
];

const initialState = {
   notiList: mockData
};

const NotiModuleReducer = (state = initialState, action) =>{
    const { payload } = action;
    let changed = state;

    switch (action.type){
        case CHECK_READ :{
            const { id, status } = payload;

            let newList = state.notiList;
            for (var index in newList) {
                if (newList[index].id === id) {
                    newList[index].status = status;
                    break;
                }
            }
            changed = {
                ...state,
                notiList: newList,
            };
        }

    }
    return changed;
};
export default NotiModuleReducer;