// ** Navigation imports
import dashboards from "./dashboards";
import todoManagement from "./todo-management";
import roleManagement from "./role-management";
import farmManagement from "./farm-management";
import stockManagement from "./stock-management";
import repairModule from "./repair-module";
import licenseModule from "./license-module";
import empManagement from "./timeattendance";

// ** Merge & Export
/* eslint-disable */
export default [
    ...dashboards,
    ...roleManagement,
    ...todoManagement,
    ...farmManagement,
    ...stockManagement,
    ...repairModule,
    ...licenseModule,
    ...empManagement,
];
