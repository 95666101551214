import React from "react";
import styled from "styled-components";
import { ArrowLeft } from 'react-feather';

const GoBackIconStyled = styled(ArrowLeft)`
    &{
        margin-right: 10px;
    }

    &:hover {
        cursor: pointer;
    }
`;

const GoBackIcon = ({ ...buttonProperties }) => (
    <GoBackIconStyled  {...buttonProperties}/>
);

export default GoBackIcon;