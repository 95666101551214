export const CATCHING_ACCESS = {
    I: "access",
    a: "apps.farm_module.catching.access",
};
export const CATCHING_REQUISITION_CREATE = {
    I: "post",
    a: "apps.farm_module.catching.requisition_form.create",
};
export const CATCHING_REQUISITION_REJECT = {
    I: "put",
    a: "apps.farm_module.catching.requisition_form.reject",
};
export const CATCHING_REPORT_GENERAL_VIEW = {
    I: "get",
    a: "apps.farm_module.catching.report.view_general_information",
};
export const CATCHING_REPORT_GENERAL_UPDATE = {
    I: "put",
    a: "apps.farm_module.catching.report.update_general_information",
};
export const CATCHING_REPORT_SHRIMP_SIZE_VIEW = {
    I: "get",
    a: "apps.farm_module.catching.report.view_shrimp_size",
};
export const CATCHING_REPORT_SHRIMP_SIZE_UPDATE = {
    I: "put",
    a: "apps.farm_module.catching.report.update_shrimp_size",
};
export const CATCHING_REPORT_SHRIMP_WEIGHT_VIEW = {
    I: "get",
    a: "apps.farm_module.catching.report.view_shrimp_weight",
};
export const CATCHING_REPORT_SHRIMP_WEIGHT_UPDATE = {
    I: "put",
    a: "apps.farm_module.catching.report.update_shrimp_weight",
};
export const CATCHING_REPORT_SHRIMP_PRICE_VIEW = {
    I: "get",
    a: "apps.farm_module.catching.report.view_shrimp_price",
};
export const CATCHING_REPORT_SHRIMP_PRICE_UPDATE = {
    I: "put",
    a: "apps.farm_module.catching.report.update_shrimp_price",
};
export const CATCHING_REPORT_SHRIMP_SUMMARY_VIEW = {
    I: "get",
    a: "apps.farm_module.catching.report.view_table_shrimp_summary",
};
export const CATCHING_REPORT_TRANSPORTATION_VIEW = {
    I: "get",
    a: "apps.farm_module.catching.report.view_transportation",
};
export const CATCHING_REPORT_TRANSPORTATION_UPDATE = {
    I: "put",
    a: "apps.farm_module.catching.report.update_transportation",
};
export const CATCHING_BUYER_VIEW = {
    I: "get",
    a: "apps.farm_module.sampling.catching_report.view_buyer",
};
export const CATCHING_BUYER_UPDATE = {
    I: "put",
    a: "apps.farm_module.catching.report.update_buyer",
};
