import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./i18n";
import AlertPopup from "components/AlertPopup";
import Header from "components/Header";
// import Footer from './components/Footer';

import Auth_Page404 from "views/auth-views/404/Page404";

import Views from "views";

function App() {
    return (
        <>
            <BrowserRouter>
                <Views />
            </BrowserRouter>
        </>
    );
}

export default App;
