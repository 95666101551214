import { CATCHING, FARM_MODULE } from "constants/LocalhostConstant";
import {
    REMOVE_CATCHING,
    SET_CATCHING_LIST,
    UPDATE_CATCHING_STATUS,
} from "redux/constants/farmModule_catching.constants";
import { getFromLS, saveToLS } from "utility/LocalStorage";

const initialState = {
    catchingList: [],
};

const farmModuleReducer = (
    state = getFromLS(FARM_MODULE, CATCHING)
        ? getFromLS(FARM_MODULE, CATCHING)
        : initialState,
    action
) => {
    const { type, payload } = action;
    let changed = state;

    switch (type) {
        case SET_CATCHING_LIST: {
            changed = {
                ...changed,
                catchingList: payload,
            };
            break;
        }
        case REMOVE_CATCHING: {
            changed = {
                ...changed,
                catchingList: changed.catchingList.filter(
                    (catching) => catching._id !== payload
                ),
            };
            break;
        }
        case UPDATE_CATCHING_STATUS: {
            changed = {
                ...changed,
                catchingList: changed.catchingList.map((catching) =>
                    catching._id === payload.id
                        ? { ...catching, status: payload.status }
                        : catching
                ),
            };
            break;
        }
        default:
            break;
    }

    saveToLS(FARM_MODULE, CATCHING, changed);
    return changed;
};

export default farmModuleReducer;
