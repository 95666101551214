import { combineReducers } from "redux";
import alertReducer from "redux/reducers/alert.reducer";
import generalReducer from "redux/reducers/general.reducer";
import userReducer from "redux/reducers/user.reducer";
import layoutReducer from "redux/reducers/layout.reducer";
import PaginateReducer from "redux/reducers/paginate.reducer";
import DropdownReducer from "redux/reducers/dropdown.reducer";
import FormDailyReportReducer from "redux/reducers/formDialyReport.reducer";
import FormLabReportReducer from "redux/reducers/formLabReport.reducer";
import FarmModuleReducer from "./farmModule.reducer";
import FarmModule_AFPD_Reducer from "./farmModule_afpd.reducer";
import FarmModule_Sampling_Reducer from "./farmModule_sampling.reducer";
import FarmModule_Catching_Reducer from "./farmModule_catching.reducer";
import FarmModule_APD_Reducer from "./farmModule_apd.reducer";
import todoListReducer from "./todoList.reducer";
import calendar from "../calendarStore/index";
import RepairModuleReducer from "./repair.reducer";
import LicenseModuleReducer from "./license.reducer";
import StockModuleReducer from "./stock.reducer";
import NotiModuleReducer from "./notification.reducer";

export default combineReducers({
    alert: alertReducer,
    general: generalReducer,
    layout: layoutReducer,
    user: userReducer,
    dropdown: DropdownReducer,
    paginate: PaginateReducer,
    formDialyReport: FormDailyReportReducer,
    formLabReport: FormLabReportReducer,
    farmModule: FarmModuleReducer,
    farmModule_afpd: FarmModule_AFPD_Reducer,
    farmModule_sampling: FarmModule_Sampling_Reducer,
    farmModule_catching: FarmModule_Catching_Reducer,
    farmModule_apd: FarmModule_APD_Reducer,
    todoListModule: todoListReducer,
    repair: RepairModuleReducer,
    calendar: calendar,
    license: LicenseModuleReducer,
    stock: StockModuleReducer,
    noti: NotiModuleReducer,
});
