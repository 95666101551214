import { lazy } from "react";

const PlanningListPage = lazy(async () => {
    return import("./PlanningList");
});
const AFPDPayment = lazy(async () => {
    return import("./Payment");
});
const AFPDChecking = lazy(async () => {
    return import("./Checking");
});
const AFPDPlaning3 = lazy(async () => {
    return import("./Planing3");
});
const OrderApprovalModal = lazy(async () => {
    return import("./OrderApprovalModal");
});

export const AFPD_Route = [
    {
        index: true,
        path: "",
        element: <PlanningListPage />,
    },
    {
        path: "planning",
        children: [
            {
                index: true,
                path: ":state",
                element: <AFPDPlaning3 />,
            },
            {
                index: true,
                path: ":state/:id",
                element: <AFPDPlaning3 />,
            },
        ],
    },
    {
        index: true,
        path: "report/:state/:id",
        element: <AFPDChecking />,
    },
    {
        index: true,
        path: "payment/:state/:id",
        element: <AFPDPayment />,
    },
    {
        index: true,
        path: "order-approval",
        element: <OrderApprovalModal />,
    },
];
