import { lazy } from "react";
import { APP_PREFIX_PATH, ATTEND_MODULE_PATH } from "configs/AppConfig";

const EmployeePage = lazy(async () => {
    return import("./EmployeePage");
});
const EmpManage = lazy(async () => {
    return import("./EmpManagement");
});
const SalaryPage = lazy(async () => {
    return import("./SalaryPage");
});
export const RouteAttendanceModule = {
    path: `${APP_PREFIX_PATH}/${ATTEND_MODULE_PATH}`,
    children: [
        {
            index: true,
            path: "employee",
            element: <EmployeePage />,
        },
        {
            index: true,
            path: "empmanage",
            element: <EmpManage />,
        },
        {
            index: true,
            path: "salary",
            element: <SalaryPage />,
        },
    ],
};
