import server from "./constants/Farm";
import { constAxios } from "./generalFunction";
import { httpClient_App as httpClient } from "https/List";

const PondService = {};

PondService.serviceStartPreparing = (payload) =>
    httpClient(
        constAxios({
            service: server.START_PREPARING,
            payload: payload,
        }),
        true
    );
PondService.serviceCancelPreparing = (payload) =>
    httpClient(
        constAxios({
            service: server.CANCEL_PREPARING,
            payload: payload,
        }),
        true
    );
PondService.serviceStartFarming = (payload) =>
    httpClient(
        constAxios({
            service: server.START_FARMING,
            payload: payload,
        }),
        true
    );

export default PondService;
