import { AxiosModel } from "models/index";

// prettier-ignore
const server = {
    // FARM
    GET_FARM_LIST: new AxiosModel({method: "GET", url: "apis/user/list", checkRefreshToken: true}),
    GET_FARM_DETAIL_BY_ID: new AxiosModel({method: "GET", url: "apis/farm/:farmId/", checkRefreshToken: true}),
    FARM_CREATE: new AxiosModel({method: "POST", url: "apis/farm/", checkRefreshToken: true, typeBody: 4}),
    FARM_EDIT: new AxiosModel({method: "PATCH", url: "apis/farm/:farmId/", checkRefreshToken: true, typeBody: 4}),
    FARM_DELETE: new AxiosModel({method: "DELETE", url: "apis/farm/:farmId/", checkRefreshToken: true}),

    // POND
    GET_POND_LIST: new AxiosModel({method: "GET", url: "apis/user/list", checkRefreshToken: true}),
    GET_POND_TYPE_LIST: new AxiosModel({method: "GET", url: "apis/farm/:farmId/pond/type", checkRefreshToken: true}),
    POND_CREATE: new AxiosModel({method: "POST", url: "apis/farm/:farmId/pond/", checkRefreshToken: true, typeBody: 4}),
    POND_EDIT: new AxiosModel({method: "PATCH", url: "apis/farm/:farmId/pondlist/:pondListId/pond/:pondId/", checkRefreshToken: true, typeBody: 4}),
    GET_POND_DETAIL: new AxiosModel({method: "GET", url: "apis/farm/:farmId/pond/:pondId", checkRefreshToken: true}),
    POND_CONNECT: new AxiosModel({method: "PATCH", url: "apis/farm/:farmId/pondlist/:pondListId/pond/:pondId/connect", checkRefreshToken: true, typeBody: 4}),
    POND_CONNECT_EDIT: new AxiosModel({method: "PATCH", url: "apis/farm/:farmId/pondlist/:pondListId/pond/:pondId/connect/edit", checkRefreshToken: true, typeBody: 4}),
    DELETE_POND: new AxiosModel({method: "DELETE", url: "apis/farm/:farmId/pondlist/:pondListId/pond/:pondId", checkRefreshToken: true}),
    
    // Catching
    CATCHING_SCHEDULE_CREATE: new AxiosModel({method: "POST", url: "apis/pond/:pondId/catching/schedule/create/", checkRefreshToken: true, typeBody: 4}),
    CATCHING_REPORT_CREATE: new AxiosModel({method: "POST", url: "apis/pond/:pondId/catching/report/create/", checkRefreshToken: true, typeBody: 4}),

    // Schedule
    SAMPLING_SCHEDULE_GET_LIST: new AxiosModel({method: "GET", url: "/apis/pond/:pondId/sampling/schedule/list?next&previous", checkRefreshToken: true}),
    SAMPLING_REPORT_CREATE: new AxiosModel({method: "POST", url: "apis/pond/:pondId/sampling/report", checkRefreshToken: true, typeBody: 1}),
    
    START_PREPARING: new AxiosModel({method: "PATCH", url: "apis/farm/:farmId/pond/:pondId/startPreparing", checkRefreshToken: true, typeBody: 4}),
    CANCEL_PREPARING: new AxiosModel({method: "PATCH", url: "apis/farm/:farmId/pond/:pondId/cancelPreparing", checkRefreshToken: true, typeBody: 4}),
    START_FARMING: new AxiosModel({method: "PATCH", url: "apis/farm/:farmId/pond/:pondId/startFarming", checkRefreshToken: true, typeBody: 4}),

    // Report
    CREATE_GENERAL_REPORT: new AxiosModel({method: "POST", url: "apis/report/daily/", checkRefreshToken: true, typeBody: 4}),
    READ_GENERAL_REPORT: new AxiosModel({method: "GET", url: "apis/report/daily/:id", checkRefreshToken: true, typeBody: 4}),
    UPDATE_GENERAL_REPORT: new AxiosModel({method: "PATCH", url: "apis/report/daily/:id", checkRefreshToken: true, typeBody: 4}),
    
    CREATE_LABSHRIMP_REPORT: new AxiosModel({method: "POST", url: "apis/report/labshrimp/", checkRefreshToken: true, typeBody: 4}),
    READ_LABSHRIMP_REPORT: new AxiosModel({method: "GET", url: "apis/report/labshrimp/:id", checkRefreshToken: true, typeBody: 4}),
    UPDATE_LABSHRIMP_REPORT: new AxiosModel({method: "PATCH", url: "apis/report/labshrimp/:id", checkRefreshToken: true, typeBody: 4}),
    
    CREATE_LABWATER_REPORT: new AxiosModel({method: "POST", url: "apis/report/labwater/", checkRefreshToken: true, typeBody: 4}),
    READ_LABWATER_REPORT: new AxiosModel({method: "GET", url: "apis/report/labwater/:id", checkRefreshToken: true, typeBody: 4}),
    UPDATE_LABWATER_REPORT: new AxiosModel({method: "PATCH", url: "apis/report/labwater/:id", checkRefreshToken: true, typeBody: 4}),

    GET_PROGRESS: new AxiosModel({method: "GET", url: "apis/report/:pondId/progress", checkRefreshToken: true, typeBody: 4}),
    GET_PROGRESS_MONTH: new AxiosModel({method: "GET", url: "apis/report/:pondId/progress/allday", checkRefreshToken: true, typeBody: 4}),
    GET_REPORT_LIST: new AxiosModel({method: "GET", url: "apis/report/:pondId/list", checkRefreshToken: true, typeBody: 4}),
};

export default server;
