import React, { useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AbilityContext } from "utility/ability/createContextualCan";
import ability from "utility/ability/ability";
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";

import { Provider } from "react-redux";
import store from "redux/store";

const AppWithRouter = () => {
    const [queryClient] = useState(() => {
        return new QueryClient({
            defaultOptions: {
                queries: {
                    retry: false,
                },
            },
        });
    });

    return (
        <Provider store={store}>
            <AbilityContext.Provider value={ability}>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </AbilityContext.Provider>
        </Provider>
    );
};
ReactDOM.render(<AppWithRouter />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
