import {
    SET_PLANNING_LIST,
    SET_PLANNING_INFO,
    CHANGE_STATUS,
} from "../constants/farmModule_afpd.constants";

import { saveToLS, getFromLS } from "utility/LocalStorage";
import { FARM_MODULE, AFPD } from "constants/LocalhostConstant";

const initialState = {
    planningList: [],
    planningInfo: {},
};

const farmModuleReducer = (
    state = getFromLS(FARM_MODULE, AFPD)
        ? getFromLS(FARM_MODULE, AFPD)
        : initialState,
    action
) => {
    const { type, payload } = action;
    let changed = state;

    switch (type) {
        case SET_PLANNING_LIST: {
            changed = {
                ...state,
                planningList: payload,
            };
            break;
        }

        case SET_PLANNING_INFO: {
            changed = {
                ...state,
                planningInfo: payload,
            };
            break;
        }

        case CHANGE_STATUS: {
            const { id, status } = payload;

            let newList = state.planningList;
            for (let index in newList) {
                if (newList[index].id == id) {
                    newList[index].status = status;
                }
            }
            changed = {
                ...state,
                planningList: newList,
            };
            break;
        }

        default:
            break;
    }

    saveToLS(FARM_MODULE, AFPD, changed);
    return changed;
};

export default farmModuleReducer;
