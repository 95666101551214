import { 
    HANDLE_SKIN, 
    HANDLE_MENU_COLLAPSED,
    HANDLE_FOOTER_TYPE,
    HANDLE_NAVBAR_TYPE,
    HANDLE_MENU_HIDDEN,
    HANDLE_CONTENT_WIDTH,
    HANDLE_ROUTER_TRANSITION
} from '../constants/layout.constant';

const initialState = {
    skin: "light",
    menuCollapsed: false,
    footerType: "static",
    navbarType: "floating",
    menuHidden: false,
    contentWidth: "boxed",
    routerTransition: "fadeIn",
  };

const layoutReducer = (state = initialState, action) => {
  switch(action.type) {

    case HANDLE_SKIN:
      return {...state, skin: action};

    case HANDLE_MENU_COLLAPSED:
      return {...state, menuCollapsed: action};

    case HANDLE_FOOTER_TYPE:
      return {...state, footerType: action};

    case HANDLE_NAVBAR_TYPE:
        return {...state, navbarType: action};

    case HANDLE_MENU_HIDDEN:
        return {...state, menuHidden: action};

    case HANDLE_CONTENT_WIDTH:
        return {...state, contentWidth: action};
    
    case HANDLE_ROUTER_TRANSITION:
        return {...state, routerTransition: action};
      
    default:
      return state;
  }
};

export default layoutReducer;