export class LabWaterModel {
    constructor(){
        // Lab Water
        this.detail ={
            Collect_Time : undefined,
            Collect_Place : undefined,
            Collect_Method : undefined,
            Collect_Volume : undefined,
            Collect_Deep : undefined,
        }
        // Water Anomally
        this.inWater ={
            Yellow_Water : undefined,
            Green_Water : undefined,
            Black_Water : undefined,
            Total_Water : 0,
            WaterAnomally_Image : [],
        }
    };
}