import { lazy } from "react";

const APDList = lazy(async () => {
    return import("./APDList");
});
const BuyerList = lazy(async () => {
    return import("./BuyerList");
});
const Payment = lazy(async () => {
    return import("./PaymentPage");
});

export const APD_Route = [
    {
        index: true,
        path: "",
        element: <APDList />,
    },
    {
        path: ":id",
        children: [
            {
                index: true,
                path: "",
                element: <BuyerList />,
            },
            {
                path: "payment",
                children: [
                    {
                        index: true,
                        path: ":paymentid",
                        element: <Payment />,
                    },
                ],
            },
        ],
    },
];
