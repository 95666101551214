export const ACCESS_LICENSE = { I: "access", a: "apps.license_module.access" };
export const CREATE_LICENSE = {
    I: "post",
    a: "apps.license_module.license.create",
};
export const UPDATE_LICENSE = {
    I: "put",
    a: "apps.license_module.license.update",
};
export const DELETE_LICENSE = {
    I: "delete",
    a: "apps.license_module.license.delete",
};

export const SETTING_LICENSE = {
    I: "get",
    a: "apps.license_module.setting.member",
};
export const ACCESS_LICENSE_SETTING = {
    I: "access",
    a: "apps.license_module.setting",
};
