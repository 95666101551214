import {
    SET_DROPDOWN,
    DROPDOWN_CHANGE_VISIBLE,
    DROPDOWN_CHANGE_OPTION,
    DROPDOWN_CHANGE_POSTION,
    DROPDOWN_CHANGE_DATA,
} from "redux/constants/dropdown.constants";

const initialState = {
    visible: false,
    position: {
        top: 0,
        left: 0,
    },
    option: [],
    data: {},
};

const DropdownReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let changed = state;

    switch (type) {
        case SET_DROPDOWN:
            changed = payload;
            break;

        case DROPDOWN_CHANGE_VISIBLE: {
            changed = {
                ...state,
                visible: payload,
            };
            break;
        }

        case DROPDOWN_CHANGE_OPTION: {
            changed = {
                ...state,
                option: payload,
            };
            break;
        }

        case DROPDOWN_CHANGE_POSTION: {
            changed = {
                ...state,
                position: payload,
            };
            break;
        }

        case DROPDOWN_CHANGE_DATA: {
            changed = {
                ...state,
                data: payload,
            };
            break;
        }

        default:
            break;
    }

    return changed;
};

export default DropdownReducer;
