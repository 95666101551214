import { lazy } from "react";

const RequestPage = lazy(async () => {
    return import("./Main");
});

const PrepareItemsPage = lazy(async () => {
    return import("./PrepareItems");
});

const RecieveItemsPage = lazy(async () => {
    return import("./RecieveItems");
});

const RouteReport = [
    {
        index: true,
        path: "",
        element: <RequestPage />,
    },
    {
        index: true,
        path: "prepare",
        element: <PrepareItemsPage />,
    },
    {
        index: true,
        path: "recieveItems",
        element: <RecieveItemsPage />,
    },
];

export default RouteReport;
