import httpClient from "https/App";
import server from "./constants/Authentication";
import { constAxios } from "./generalFunction";

const AuthenticationServices = {};

// prettier-ignore
AuthenticationServices.loginService = (payload) =>
    httpClient(
        constAxios({
            service: server.SIGN_IN,
            payload: payload,
        }),
        true
    );

AuthenticationServices.request_OTP = (payload) =>
    httpClient(
        constAxios({
            service: server.REQUEST_OTP,
            payload: payload,
        }),
        false
    );

AuthenticationServices.verify_OTP = (payload) =>
    httpClient(
        constAxios({
            service: server.VERIFY_OTP,
            payload: payload,
        }),
        false
    );

AuthenticationServices.signupService = (payload) =>
    httpClient(
        constAxios({
            service: server.SIGN_UP,
            payload: payload,
        }),
        false
    );

AuthenticationServices.requestOTPResetPasswordService = (payload) =>
    httpClient(
        constAxios({
            service: server.RESET_PASSWORD_REQUEST_OPT,
            payload: payload,
        }),
        false
    );

AuthenticationServices.verifyOTPResetPasswordService = (payload) =>
    httpClient(
        constAxios({
            service: server.RESET_PASSWORD_VERIFY_OPT,
            payload: payload,
        }),
        false
    );

AuthenticationServices.resetPassword = (payload) =>
    httpClient(
        constAxios({
            service: server.RESET_PASSWORD,
            payload: payload,
        }),
        false
    );

export default AuthenticationServices;
