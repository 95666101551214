export default class LicenseModel {
    constructor(data) {
        this.id = data?.id || 999;
        this.category = data?.category || "";
        this.lebel = data?.lebel || "";
        this.licenseNumber = data?.licenseNumber || "";
        this.expiredate = data?.expiredate || "";
        this.status = data?.status || undefined;
        this.image = data?.image || [];
        this.owner = data?.owner || "";
        this.farm = data?.farm || "";
    }
}