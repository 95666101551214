import { lazy } from "react";
import { APP_PREFIX_PATH, NOTI_MODULE_PATH } from "configs/AppConfig";

const NotificationPage = lazy(async () => {
    return import("./NotificationPage");
});

export const RouteNotiModule = {
    path: `${APP_PREFIX_PATH}/${NOTI_MODULE_PATH}`,
    children: [
        {
        index: true,
        path: 'list',
        element: <NotificationPage />,
        },
    ]
}
