import { Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { connect } from "react-redux";
import {
    APP_PREFIX_PATH,
    DASHBOARD_MODULE_PATH,
    ROLE_MODULE_PATH,
} from "configs/AppConfig";

import { getFarmList } from "redux/actions/farmModule/farm.actions";

import  { Dashboard } from "./DashboardRemodal/Dashboard"
import { RouteDashboardModule } from "./DashboardModule";
import RouteRoleModule from "./RoleModule";
import { RouteFarmModule } from "./FarmModule";
import { RouteStockModule } from "./StockModule";
import { RouteTodoModule } from "./TodoListModule";
import { RouteRepairModule } from "./RepairModule";
import { RouteLicenseModule } from "./LicenseModule";
import { RouteNotiModule } from "./NotiModule";
import { RouteAttendanceModule } from "./AttendanceModule";

// ! https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
// ? Document : https://github.com/remix-run/react-router/blob/main/packages/react-router/lib/router.ts#L86-L96

export const AdminViews = () => {
    const routesConfig = [
        {
            path: `${APP_PREFIX_PATH}/${DASHBOARD_MODULE_PATH}/test`,
            element: <Dashboard />,
        },
        RouteDashboardModule,
        {
            path: `${APP_PREFIX_PATH}/${ROLE_MODULE_PATH}`,
            children: RouteRoleModule,
        },
        RouteFarmModule,
        RouteStockModule,
        RouteTodoModule,
        RouteRepairModule,
        RouteLicenseModule,
        RouteNotiModule,
        RouteAttendanceModule,
        // {
        //     path: "*",
        //     element: (
        //         <Navigate to={`${APP_PREFIX_PATH}/${FARM_MODULE_PATH}/farms`} />
        //     ),
        // },
    ];

    const routes = useRoutes(routesConfig);

    return <Suspense fallback={<></>}>{routes}</Suspense>;
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
    getFarmList,
})(AdminViews);
