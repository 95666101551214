import { USER_SIGNIN, USER_SIGNOUT } from 'redux/constants/user.constant';
import { UserModel } from 'models';
import { PREFIX } from 'constants/variables';

const tempUser = localStorage.getItem(`${PREFIX}_USER`);
const initialState = new UserModel(tempUser? JSON.parse(tempUser): {});
var temp;

const userReducer = (state = initialState, action) => {
  switch(action.type) {

    case USER_SIGNIN:
      temp = new UserModel(action.payload);
      localStorage.setItem(`${process.env.REACT_APP_PREFIX}_USER`, JSON.stringify(temp));
      return { ...state, ...temp };
    
    case USER_SIGNOUT:
      temp = new UserModel({});
      localStorage.removeItem(`${process.env.REACT_APP_PREFIX}_USER`, JSON.stringify(temp));
      return { ...state, ...temp };

    default:
      return state;
  }
};

export default userReducer;