import { USER_SIGNIN, USER_SIGNOUT } from "redux/constants/user.constant";
import AuthenticationServices from "services/AuthenticationServices";
// import { removeListLS } from 'helpers/localStorage';
import { alertToast } from "redux/actions/alert.actions";

export const userSignin = (payload) => async (dispatch) => {
    const { status, data } = await AuthenticationServices.loginService({
        data: {
            username: payload.data.username,
            password: payload.data.password,
        },
    });

    if (status === 200) {
        dispatch({
            type: USER_SIGNIN,
            payload: {
                authId: data.id,
                uid: data.uid,
                username: data.username,
                email: data.email,
                role: data.role,
            },
        });
        payload.next();
        dispatch(
            alertToast("success", {
                message: "เข้าสู่ระบบสำเร็จ",
            })
        );
    } else {
        dispatch(
            alertToast("error", {
                message: "เข้าสู่ระบบไม่สำเร็จ",
            })
        );
    }

    return true;
};

export const requestOTP = (payload) => async (dispatch) => {
    const { status, data } = await AuthenticationServices.request_OTP({
        data: {
            phoneNumber: payload.data,
        },
    });

    if (status === 200) {
        dispatch(
            alertToast("success", {
                message: `ส่ง OTP ไปยัง ${payload.data} เรียบร้อย`,
            })
        );
        payload.next(data.userId);
    } else {
        if (data?.message === "This number is already used") {
            dispatch(
                alertToast("success", {
                    message: "เบอร์โทรศัพท์นี้ถูกใช้งานแล้ว",
                })
            );
            payload.next();
        }
    }

    return true;
};

export const verifyOTP = (payload) => async (dispatch) => {
    const { status, data } = await AuthenticationServices.verify_OTP({
        data: payload.data,
    });

    if (status === 200) {
        payload.next();
    } else {
    }

    return true;
};

export const userSignup = (payload) => async (dispatch) => {
    const { status, data } = await AuthenticationServices.signupService({
        data: payload.data,
    });

    if (status === 200) {
        dispatch(
            alertToast("success", {
                message: "สร้างบัญชีผู้ใช้งานสำเร็จ",
            })
        );
    } else {
    }

    return true;
};

export const requestOTPResetPassword = (payload) => async (dispatch) => {
    const { status, data } =
        await AuthenticationServices.requestOTPResetPasswordService({
            data: payload.data,
        });

    if (status === 200) {
        dispatch(
            alertToast("success", {
                message: `ส่งคำร้องขอเปลี่ยนรหัสผ่านเรียบร้อย`,
            })
        );
        payload.next(data.userId);
    } else {
        dispatch(
            alertToast("error", {
                message: data.message,
            })
        );
    }

    return true;
};

export const verifyOTPResetPassword = (payload) => async (dispatch) => {
    const { status, data } =
        await AuthenticationServices.verifyOTPResetPasswordService({
            data: payload.data,
        });

    if (status === 200) {
        dispatch(
            alertToast("success", {
                message: `รหัส OTP ถูกต้อง`,
            })
        );
        payload.next();
    } else {
        dispatch(
            alertToast("error", {
                message: data.message,
            })
        );
    }

    return true;
};

export const resetPassword = (payload) => async (dispatch) => {
    const { status, data } = await AuthenticationServices.resetPassword({
        data: payload.data,
    });

    if (status === 200) {
        dispatch(
            alertToast("success", {
                message: `เปลี่ยนรหัสผ่านสำเร็จ`,
            })
        );
        payload.next();
    } else {
        dispatch(
            alertToast("error", {
                message: data.message,
            })
        );
    }

    return true;
};

export const userSignout = (payload) => (dispatch) => {
    dispatch({ type: USER_SIGNOUT, payload: {} });
    return true;
};
