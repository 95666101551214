import { User, Users } from "react-feather";

import { CalendarIconOutline } from "components/Icons";

import { APP_PREFIX_PATH, TODO_MODULE_PATH } from "configs/AppConfig";

const PREFIX = `${APP_PREFIX_PATH}/${TODO_MODULE_PATH}`;

/* eslint-disable */
export default [
    {
        header: "todo",
    },
    // {
    //     id: "todo-list",
    //     title: "สิ่งที่ต้องทำ",
    //     icon: <User />,
    //     navLink: `${PREFIX}/todo-list`,
    // },
    {
        id: "todo-schedule",
        title: "ปฏิทิน",
        icon: <CalendarIconOutline size={50} />,
        navLink: `${PREFIX}/schedule`,
    },
];
