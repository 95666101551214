import { lazy } from "react";
import { APP_PREFIX_PATH, FARM_MODULE_PATH } from "configs/AppConfig";
import store from "redux/store";
import { RouteFarm } from "./Farm";
import { AFPD_Route } from "./AFPD";
import { Sampling_Route } from "./Sampling";
import { Catching_Route } from "./Catching";
import { APD_Route } from "./APD";

const SettingFarmSystemPage = lazy(async () => {
    const { getState } = store;
    console.log(getState());
    return import("./Setting");
});
const FarmCreationPage = lazy(async () => {
    return import("./Farm/CreateFarm");
});
const EditFarmPage = lazy(async () => {
    return import("./Farm/EditFarm");
});


export const RouteFarmModule = {
    path: `${APP_PREFIX_PATH}/${FARM_MODULE_PATH}`,
    children: [
        {
            path: "afpd",
            children: AFPD_Route,
        },
        {
            path: "apd",
            children: APD_Route,
        },
        {
            path: "sampling",
            children: Sampling_Route,
        },
        {
            path: "catching",
            children: Catching_Route,
        },
        {
            path: "setting",
            children: [
                {
                    index: true,
                    path: "",
                    element: <SettingFarmSystemPage />,
                },
                {
                    index: true,
                    path: "farm-create",
                    element: <FarmCreationPage />,
                },
                {
                    index: true,
                    path: ":id",
                    element: <EditFarmPage />,
                },
            ],
        },
        {
            path: "farms",
            children: RouteFarm,
        },
    ],
};
